import api from "./http-commons";
import { CategoryActiveResult, CategoryResponse, RegisterCategoryUser } from "interfaces/category.interface";

export const CategoryServices = () => {

   const getActiveCategory = async () => {
      const res = await api.get('/active/category');
      const result: CategoryActiveResult = res.data;
      return result;
   }

   const registerUserCategory = async (array:RegisterCategoryUser) => {
      const res = await api.post('/user/category', JSON.stringify(array));
      const result:CategoryResponse = res.data;
      return result;
   }

   return {
      getActiveCategory,
      registerUserCategory
   }
};
