import { MenuDark } from "components/menu";

const PremiumPage = () => {

   return (
      <>
         <div className="container-fluid premium-image">
            <div className="row">
               <MenuDark />
               <div className="col-sm-6 offset-sm-1 animated fadeIn fast">
                  <h2>Premium Membership</h2>
                  <p>
                     By becoming a premium member, you have unlimited access to all available audiobooks, add them to your library and enjoy them on your own time instead of waiting for them.
                  </p>
                  <p>
                     For a monthly subscription of $5 dollars, you can enjoy all the premium benefits. Cancel at any time.
                  </p>
                  <p>Login and go to "My Profile".</p>
                  <br />
               </div>
            </div>
         </div>
      </>
   );
}

export default PremiumPage
