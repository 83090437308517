import { useContext, useState } from 'react';
import { UserLayout } from 'layout';
import BookRegister from './BookRegister';
import UserContext from 'context/User/UserContext';
import { UserContextType } from 'context/User/UserTypes';
import BookRegisterList from './BookRegisterList';


const BookRegisterPage = () => {
   const { userContext } = useContext(UserContext) as UserContextType;
   const [reload, setReload] = useState(false);


   return (
      <>
         <UserLayout title="Sharing is caring" col="10" off="1">
            <p>
            Progresemos appreciates your support and willingness to share your favorite audiobooks. If the book you would like to share, doesn’t have an audible version yet, we will reproduce it, simply share the book’s link. All share audiobooks will be validated and approved by our team.
            </p>
            <BookRegister userKey={userContext.userKey} setReload={setReload} />
            <BookRegisterList userKey={userContext.userKey} reload={reload} setReload={setReload} />
         </UserLayout>
      </>
   );
}

export default BookRegisterPage
