import { Button } from 'components/atoms';
import { Link } from 'react-router-dom';

const MenuDark = () => {
   return (
      <>
         <nav className="navbar navbar-expand-lg navbar-custom">
            <div className="container-fluid">
               <Link className="navbar-brand" to={'/'}>
                  <img src={process.env.PUBLIC_URL + `/assets/logo-blue.png`} alt="Progresemos" width={150} />
               </Link>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <img src={process.env.PUBLIC_URL + `/assets/bars-blue.png`} alt="Progresemos" />
               </button>
               <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className="me-auto" />
                  <ul className="navbar-nav navbar-dark d-flex">
                     <li className="nav-item">
                        <Link className={'nav-link mt-2'} to={'/'}>Home</Link>
                     </li>
                     <li className="nav-item">
                        <Link className={'nav-link mt-2'} to={'/register'}>Register</Link>
                     </li>
                     <li className="nav-item">
                        <Link className={'nav-link mt-2'} to={'/contact'}>Contact</Link>
                     </li>
                     <li className="nav-item">
                        <Link className={'nav-link'} to={'/login'}>
                           <Button type={'outline-blue'} title={'Login'} />
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </>
   );
}

export default MenuDark

