import api from './http-commons'
import {
  LoginData,
  LoginResponse,
  RegisterData,
  UpdateUserData,
  UserDataResult,
  iRecover,
  iCredential,
} from '../interfaces/web.interfaces'

export const LoginServices = () => {
  const signIn = async (array: LoginData) => {
    const res = await api.post('/login/user', JSON.stringify(array))
    const result: LoginResponse = res.data
    return result
  }

  const signUp = async (array: RegisterData) => {
    const res = await api.post('/create/user', JSON.stringify(array))
    const result: LoginResponse = res.data
    return result
  }

  const updateUserData = async (array: UpdateUserData) => {
    const res = await api.put('/update/data/user', JSON.stringify(array))
    return res.data
  }

  const getUser = async (userKey: string) => {
    const res = await api.get(`/user/${userKey}`)
    const result: UserDataResult = res.data
    return result
  }

  const recoverAccount = async (array: iRecover) => {
    const res = await api.post('/recover/account', JSON.stringify(array))
    return res.data
  }

  const updateCredential = async (array: iCredential) => {
    const res = await api.put('/update/credential/user', JSON.stringify(array))
    return res.data
  }

  return {
    signIn,
    signUp,
    updateUserData,
    getUser,
    recoverAccount,
    updateCredential,
  }
}
