
interface Props {
   label: string;
   name?: string;
   onChange?: any;
   type?: 'text' | 'email' | 'password' | 'number' | 'date';
   value?: string | number;
   message?: string;
   placeholder?: string;
}

export const TextInput = ({ label, ...props }: Props) => {
   const errorClass =
      props.message?.substring(0, 5) === 'Error' ? 'danger-message' : '';

   return (
      <>
         <label className="form-label">{label}</label>
         <input className="form-control input-box" {...props} />
         {props.message && (
            <div className={`form-text ${errorClass}`}>{props.message}</div>
         )}
      </>
   );
};

