import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "context/User/UserContext";
import { Button, ButtonPayPal } from "components/atoms";
import { Role } from "utils/roles";
import { UserContextType } from "context/User/UserTypes";
import { removeLocaleStorage } from "utils/session";
import "./Menu.scss";

const MenuUser = () => {
   const history = useNavigate();
   const { userStatusContext, setUserStatusContext, setUserContext } = useContext(UserContext) as UserContextType;

   useEffect(() => {
      if (userStatusContext.role === Role.SuperAdmin || userStatusContext.role === Role.Admin) {
         history("/home");
      }
   }, [])

   const handleLogout = () => {
      const userState = {
         isLogged: false,
         role: Role.Web,
         userKey: "",
         userToken: ""
      }
      const user = {
         userKey: "",
         name: "",
         lastName: "",
         mail: "",
         phone: "",
         languageKey: "",
         userStatus: 0
      }

      setUserStatusContext(userState);
      setUserContext(user);
      removeLocaleStorage('progresemos_status_context');
      removeLocaleStorage('progresemos_user_context');
      history("/home");
   }

   return (
      <>
         <nav className="navbar navbar-expand-lg navbar-custom">
            <div className="container-fluid">
               <Link className="navbar-brand" to="/">
                  <img src={process.env.PUBLIC_URL + `/assets/logo-blue.png`} alt="Progresemos" width={150} />
               </Link>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <img src={process.env.PUBLIC_URL + `/assets/bars-blue.png`} alt="Progresemos" />
               </button>
               <form className="navbar-toggler" action="https://www.paypal.com/donate" method="post" target="_blank">
                  <input type="hidden" name="hosted_button_id" value="TPXBSF9E4LQ26" />
                  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif"
                     name="submit" title="PayPal - The safer, easier way to pay online!"
                     alt="Donate with PayPal button"
                  />
                  <img alt="" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
               </form>
               <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className="me-auto" />
                  <ul className="navbar-nav navbar-dark d-flex">
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/user/preferences'>My Preferences</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/user/profile'>My Profile</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/user/search/audiobook'>Search AudioBook</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/user/files'>My Library</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/user/book/register'>Share an Audiobook</Link>
                     </li>
                     <li style={{ paddingTop: "6px" }} className="nav-item li-hide">
                        <ButtonPayPal />
                     </li>
                     <li className="nav-item">
                        <Button onClick={handleLogout} type={'outline-red'} title={'LogOut'} />
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </>
   );
}

export default MenuUser
