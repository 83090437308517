import { Button as ButtonModal, Modal } from "react-bootstrap";

interface Props {
   showModalDelete: boolean;
   setShowModalDelete: any;
   message: string;
   deleteItem: any;
   dataValue: any;
}


const DeleteConfirm = ({ showModalDelete, setShowModalDelete, message, deleteItem, dataValue }: Props) => {

   const handleBack = () => setShowModalDelete(false);
   const handleDelete = () => deleteItem(dataValue);

   return (
      <>
         <Modal show={showModalDelete} onHide={handleBack}> <Modal.Header closeButton>
            <Modal.Title>¿Delete Item?</Modal.Title> </Modal.Header>

            <Modal.Body>
               <div className="alert alert-danger">{message}</div>
            </Modal.Body>

            <Modal.Footer> <ButtonModal variant="danger" onClick={handleDelete}>Delete</ButtonModal>
               <ButtonModal variant="secondary" onClick={handleBack}>Cancel</ButtonModal>
            </Modal.Footer> </Modal>
      </>
   );
}

export default DeleteConfirm
