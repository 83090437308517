import { Navigate, Route, Routes } from "react-router-dom";
import { AccountsAdminPage, CategoryAdminPage, ChangePaswordPage, ContactPage, DownloadsAdminPage, FreeBookRegisterPage, HomeAdminPage, HomePage, LibraryPage, LoginAdminPage, LoginPage, PreferencesPage, PremiumPage, ProfilePage, RecoverPage, RedirectPage, RegisterPage, SearchBookPage, UsersAdminPage, VideoAdminPage, VideoUserAdminPage } from "pages";
import { PrivateNavigation } from "./PrivateNavigation";
import BookRegisterPage from "pages/users/bookRegister/BookRegisterPage";

const Navigation = () => {

   return (
      <>
         <Routes>
            <Route path="/home" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/recover/account" element={<RecoverPage />} />
            <Route path="/change/password/:token" element={<ChangePaswordPage />} />
            <Route path="/admin" element={<LoginAdminPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/getpremium" element={<PremiumPage />} />
            <Route path="/free/book/register" element={<FreeBookRegisterPage />} />
            <Route path="/*" element={<Navigate to="/home" />} />

            <Route element={<PrivateNavigation />}>
               <Route path="/user/preferences" element={<PreferencesPage />} />
               <Route path="/user/profile" element={<ProfilePage />} />
               <Route path="/user/files" element={<LibraryPage />} />
               <Route path="/user/book/register" element={<BookRegisterPage />} />
               <Route path="/user/search/audiobook" element={<SearchBookPage />} />
               <Route path="/admin/home" element={<HomeAdminPage />} />
               <Route path="/open/link/:userKey/:videoKey" element={<RedirectPage />} />
               <Route path="/admin/account" element={<AccountsAdminPage />} />
               <Route path="/admin/category" element={<CategoryAdminPage />} />
               <Route path="/admin/links" element={<VideoUserAdminPage />} />
               <Route path="/admin/users" element={<UsersAdminPage />} />
               <Route path="/admin/download/links" element={<DownloadsAdminPage />} />
               <Route path="/admin/video/manager" element={<VideoAdminPage />} />
            </Route>
         </Routes>
      </>
   );
}

export default Navigation;

