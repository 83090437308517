import { Button } from "components/atoms";
import { useRegister } from "hooks";
import { AuthLayout } from "layout"
import { commonText } from "utils/commons";

const RegisterPage = () => {
   const { loading, typeAlert, name, setName, lastName, setLastName, phone, setPhone, mail, setMail, password, setPassword, message, handleRegister, languages, languageKey, setLanguageKey } = useRegister();

   return (
      <>
         <AuthLayout title={commonText.signUp}>
            <div className="row animated fadeIn fast custom-p-mobile">
               <div className="col-sm-12">
                  <div className="row">
                     <div className="col-12">
                        <p>
                           Let us help you daily water your mind with new Knowledge. <br />
                        </p>
                        <br />
                        <h3>{commonText.title.userInfo}</h3>
                     </div>
                     <div className="col-sm-6">
                        <div className='mb-3 form-floating'>
                           <input
                              type="text"
                              onChange={({ target }) =>
                                 setName(target.value)
                              }
                              value={name}
                              className="form-control"
                              required
                           />
                           <label>{commonText.user.name}</label>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className='mb-3 form-floating'>
                           <input
                              type="text"
                              onChange={({ target }) =>
                                 setLastName(target.value)
                              }
                              value={lastName}
                              className="form-control"
                              required
                           />
                           <label>{commonText.user.lastName}</label>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className='mb-3 form-floating'>
                           <input
                              type="email"
                              onChange={({ target }) =>
                                 setMail(target.value)
                              }
                              value={mail}
                              className="form-control"
                              required
                           />
                           <label>{commonText.mail}</label>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className='mb-3 form-floating'>
                           <input
                              type="text"
                              onChange={({ target }) =>
                                 setPhone(target.value)
                              }
                              value={phone}
                              className="form-control"
                              required
                           />
                           <label>{commonText.user.phone}</label>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className='mb-3 form-floating'>
                           <input
                              type="password"
                              onChange={({ target }) =>
                                 setPassword(target.value)
                              }
                              value={password}
                              className="form-control"
                              required
                           />
                           <label>{commonText.password}</label>
                        </div>
                     </div>
                     <div className="col-sm-6">
                        <div className='mb-3 form-floating'>
                           <select onChange={({ target }) => setLanguageKey(target.value)} value={languageKey} className="form-control" required >
                              <option value="">Select language</option>
                              {languages.map((item, key) => (
                                 <option key={key} value={item.languageKey}>
                                    {item.name}
                                 </option>
                              ))}
                           </select>
                           <label>{commonText.user.languagePreference}</label>
                        </div>
                     </div>
                     <div className="col-12">
                        {loading && (
                           <div
                              className={`alert alert-${typeAlert}`}
                              role="alert"
                           >
                              {message}
                           </div>
                        )}
                     </div>
                     <div className="col-sm-3 offset-sm-9 mt-3">
                        <Button
                           type='primary form-control'
                           title={commonText.button.next}
                           onClick={handleRegister}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </AuthLayout>
      </>
   )
}

export default RegisterPage
