import { useContext, useEffect, useState } from "react";
import UserContext from "context/User/UserContext";
import { UserContextType } from "context/User/UserTypes";
import { Languages } from "interfaces";
import { LoginServices } from "services";
import { LanguagesServices } from "services/languages.services";
import { removeLocaleStorage, setLocalStorage } from "utils/session";

export const useProfile = () => {
   const { userContext, setUserContext } = useContext(UserContext) as UserContextType;
   const { getLanguagesUser } = LanguagesServices();
   const { updateUserData, getUser } = LoginServices();
   const [loading, setLoading] = useState(false);
   const [typeAlert, setTypeAlert] = useState('primary');
   const [message, setMessage] = useState('One moment please...');
   const [name, setName] = useState(userContext.name);
   const [lastName, setLastName] = useState(userContext.lastName);
   const [phone, setPhone] = useState(userContext.phone);
   const [memberType, setMemberType] = useState("");
   const [languageKey, setLanguageKey] = useState(userContext.languageKey);
   const [languages, setLanguages] = useState<Languages[]>([]);


   useEffect(() => {
      (async () => {
         await getLanguagesUser().then((res) => {
            setLanguages(res.data);
         })
         await getUser(userContext.userKey).then((res) => {
            setMemberType(res.data[0].memberType);
         })
      })();
      // eslint-disable-next-line
   }, [])

   const handleUpdate = async () => {
      setLoading(true);
      setTypeAlert('primary');
      setMessage('Updating...');

      const data = { userKey: userContext.userKey, name, lastName, phone, languageKey };

      await updateUserData(data).then((res) => {
         if (res.status === "success") {
            const data = {
               languageKey: languageKey,
               lastName: lastName,
               mail: userContext.mail,
               name: name,
               phone: phone,
               userKey: userContext.userKey,
               userStatus: userContext.userStatus
            }

            setUserContext(data);
            removeLocaleStorage("progresemos_user_context");
            setLocalStorage("progresemos_user_context", JSON.stringify(data));
         } else {
            //TODO:
         }
      }).finally(() => setLoading(false));
   }

   return { loading, typeAlert, message, name, setName, lastName, setLastName, phone, setPhone, languageKey, setLanguageKey, languages, handleUpdate, memberType, setMemberType }
}
