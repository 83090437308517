import api from "./http-commons";
import { UserAdmin, UserAdminDetail, UserAdminMemberStatus, UserAdminStatus } from "interfaces";

export const AdminUsersServices = () => {

   const adminUser = async (status: any) => {
      const res = await api.get(`/admin/user/${status}`);
      const result: UserAdmin = res.data;
      return result;
   };

   const adminUserDetail = async (userKey: string) => {
      const res = await api.get(`/admin/user/detail/${userKey}`);
      const result: UserAdminDetail = res.data;
      return result;
   };

   const adminVideoRegister = async (userKey: string, videoKey: string) => {
      const res = await api.get(`/admin/user/video/${userKey}/${videoKey}`);
      return res.data;
   };

   const adminUserStatus = async (array: UserAdminStatus) => {
      const res = await api.post(`/admin/user`, JSON.stringify(array));
      return res.data;
   };

   const adminUserMemberStatus = async (array: UserAdminMemberStatus) => {
      const res = await api.post(`/admin/member/user`, JSON.stringify(array));
      return res.data;
   };


   return {
      adminUser,
      adminUserDetail,
      adminUserStatus,
      adminVideoRegister,
      adminUserMemberStatus
   }
};



