import { useContext, useEffect, useState } from "react";
import UserContext from "context/User/UserContext";
import UserLayout from "layout/UserLayout"
import { Alerts, CardCategory } from "components"
import { CategoryItem } from "interfaces";
import { PreferencesServices } from "services";
import { UserContextType } from "context/User/UserTypes";

const PreferencesPage = () => {
   const { userStatusContext } = useContext(UserContext) as UserContextType;
   const { userPreferences, deletePreferences, insertPreferences } = PreferencesServices();
   const [preferencesData, setPreferencesData] = useState<CategoryItem[]>([]);
   const [categoryData, setCategoryData] = useState<CategoryItem[]>([]);
   const [loading, setLoading] = useState(true);
   const [reload, setReload] = useState(false);

   useEffect(() => {
      (async () => {
         await userPreferences(userStatusContext.userKey).then((res) => {
            setPreferencesData(res.data.userCategory);
            setCategoryData(res.data.category);
         }).finally(() => {
            setReload(false);
            setLoading(false);
         });
      })();
      // eslint-disable-next-lin
   }, [reload])

   const handleRegister = async (key: string, type: number) => {
      setLoading(true);

      const data = {
         userKey: userStatusContext.userKey,
         categoryKey: key,
      };

      const res =
         type === 0
            ? await deletePreferences(data)
            : await insertPreferences(data);

      if (res.status === 'success') {
         setReload(true);
      } else {
         // TODO: Mensaje de error
         console.log(res);
      }
   }

   return (
      <>
         <UserLayout title="Knowledge Is Power" col="10" off="1">
            <div className="row">
               <p>
                  KNOWLEDGE makes you VALUABLE, and the more valuable knowledge you have, the more money people will be willing to pay for your work/services/products
               </p>
               <br />
               <br />
               {loading && (
                  <div className="col-12">
                     <Alerts type='primary' text='Loading, one moment please...' />
                  </div>
               )}
               <h3>Preferences</h3>
               {preferencesData.length === 0 && (
                  <div className="col-12">
                     <Alerts type='warning' text='You do not have registered preferences, choose one of them from the following list.' />
                  </div>
               )}
               {preferencesData.map((item) => (
                  <div className={'col-sm-4'} key={item.categoryKey}>
                     <CardCategory
                        title={item.name}
                        description={item.description}
                        color={item.color}
                        onClick={() => handleRegister(item.categoryKey, 0)}
                        type={1}
                     />
                  </div>
               ))}
               <h3>Categories</h3>
               <p>Choose up to 3 categories</p>
               {categoryData.length === 0 && (
                  <div className="col-12">
                     <Alerts type='primary' text='We hope you enjoy the content of all our categories.' />
                  </div>
               )}
               {categoryData.map((item) => (
                  <div className={'col-sm-4'} key={item.categoryKey}>
                     <CardCategory
                        title={item.name}
                        description={item.description}
                        color={item.color}
                        onClick={() =>
                           handleRegister(item.categoryKey, 1)
                        }
                        type={0}
                     />
                  </div>
               ))}
            </div>
         </UserLayout>
      </>
   )
}

export default PreferencesPage
