import api from "./http-commons";
import { CommonResponse, RegisterCategoryUser, VideoUserListResult, VideoUserSearchResult } from "interfaces";

export const VideoServices = () => {

   const videoUserRegister = async (array: RegisterCategoryUser) => {
      const res = await api.post('/video/register/user', JSON.stringify(array));
      const result: CommonResponse = res.data;
      return result;
   }

   const videoUserList = async (userKey: string) => {
      const res = await api.get(`/video/register/${userKey}/user`);
      const result: VideoUserListResult = res.data;
      return result;
   };

   const videoUserDelete = async (videoKey: string) => {
      const res = await api.delete(`/video/register/${videoKey}/user`);
      const result: CommonResponse = res.data;
      return result;
   };

   const videoUserSearch = async (categoryKey: string, languageKey: string, term: string) => {
      const res = await api.get(`/video/user/search/${categoryKey}/${languageKey}/${term}`);
      const result: VideoUserSearchResult = res.data;
      return result;
   };


   return {
      videoUserRegister,
      videoUserList,
      videoUserDelete,
      videoUserSearch
   }
};

