import { HashRouter } from 'react-router-dom';
import Navigation from 'navigation/Navigation';
import { UserProvider } from "./context/User/UserContext";
import './App.scss';

function App() {
   return (
      <HashRouter>
         <UserProvider>
            <Navigation />
         </UserProvider>
      </HashRouter>
   );
}

export default App;
