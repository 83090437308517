import { useContext, useEffect, useState } from "react";
import UserContext from "context/User/UserContext";
import { Button, Select, SmallCategory, TextInput } from "components/atoms";
import { CatalogueServices, CategoryServices, LanguagesServices, LoginServices } from "services";
import { CategoryActiveData } from "interfaces/category.interface";
import { Languages, VideoUserSearchData } from "interfaces";
import { UserContextType } from "context/User/UserTypes";
import { UserLayout } from "layout"
import { VideoServices } from "services/video.services";
import { useForm } from "utils/useForm";

const SearchBookPage = () => {
   const INITIAL_STATE = {
      categoryKey: "all",
      languageKey: "ALLALLALL",
      term: ""
   };

   const { onChange, categoryKey, languageKey, term } = useForm(INITIAL_STATE);
   const { userStatusContext, userContext } = useContext(UserContext) as UserContextType;
   const { getLanguagesUser } = LanguagesServices();
   const { getActiveCategory } = CategoryServices();
   const { videoUserSearch } = VideoServices();
   const { addVideoToLibrary } = CatalogueServices();
   const { getUser } = LoginServices();
   const [languages, setLanguages] = useState<Languages[]>([])
   const [categories, setCategories] = useState<CategoryActiveData[]>([])
   const [videoList, setVideoList] = useState<VideoUserSearchData[]>([]);
   const [loading, setLoading] = useState(false);
   const [alert, setAlert] = useState(false);
   const [message, setMessage] = useState("");
   const [typeAlert, setTypeAlert] = useState("primary");
   const [memberType, setMemberType] = useState("");

   useEffect(() => {
      (async () => {
         await getLanguagesUser().then((res) => {
            setLanguages(res.data);
         })
         await getActiveCategory().then((res) => {
            setCategories(res.data);
         })
         await getUser(userContext.userKey).then((res) => {
            setMemberType(res.data[0].memberType);
         })
      })();
   }, [])

   const searchVideo = async () => {
      setLoading(true);
      await videoUserSearch(categoryKey, languageKey, term.length === 0 ? "all" : term).then((res) => {
         setVideoList(res.data);
      }).finally(() => setLoading(false));
   }

   const openInNewTab = (videoKey: string) => {
      if (memberType === "Free") {
         setTypeAlert("danger");
         setMessage("To open the link, you must be a premium user. Go to 'My Profile' and go premium.");
         setAlert(true);
         setTimeout(() => {
            setAlert(false);
         }, 10000);
         return;
      }
      const url = `https://www.progresemos.club/#/open/link/${userStatusContext.userKey}/${videoKey}`;
      const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
      if (newWindow) newWindow.opener = null;
   };

   const addToLibrary = async (item: VideoUserSearchData) => {
      setLoading(true);

      const data = {
         userKey: userStatusContext.userKey,
         videoKey: item.videoKey
      }

      await addVideoToLibrary(data).then((res) => {
         setTypeAlert(res.status === "exists" ? "warning" : "danger");
         if (res.status === "success") {
            setTypeAlert("primary")
         }
         setMessage(res.message);
         setAlert(true);

         res.status !== "limit"
            && setTimeout(() => {
               setAlert(false);
            }, 5000);
      }).finally(() => setLoading(false));
   }

   return (
      <>
         <UserLayout title="Search Audiobook" col="10" off="1">
            <div className="row mt-3">
               <div className="col-sm-3">
                  <Select
                     label="Video Language"
                     name="languageKey"
                     value={languageKey}
                     onChange={onChange}
                  >
                     {languages.map((item, key) => (
                        <option key={key} value={item.languageKey}>
                           {item.name}
                        </option>
                     ))}
                  </Select>
               </div>
               <div className="col-sm-3">
                  <Select
                     label="Category"
                     name="categoryKey"
                     value={categoryKey}
                     onChange={onChange}
                  >
                     <option value="all">All category</option>
                     {categories.map((item) => (
                        <option key={item.categoryKey} value={item.categoryKey}>
                           {item.name}
                        </option>
                     ))}
                  </Select>
               </div>
               <div className="col-sm-4">
                  <TextInput
                     label="Term search"
                     name="term"
                     value={term}
                     onChange={onChange}
                  />
               </div>
               <div className="col-sm-2">
                  <Button
                     type="primary form-control mt-28"
                     title="Search"
                     onClick={searchVideo}
                  />
               </div>
               <div className="col-12 mt-3">
                  {loading && (
                     <div className="alert alert-primary" role="alert">
                        One moment please...
                     </div>
                  )}
               </div>
               <div className="col-12 mt-3">
                  {alert && (
                     <div className={`alert alert-${typeAlert}`} role="alert">{message}</div>
                  )}
               </div>
               <div className="col-12 mt-3">
                  <div className="table-responsive mb-5">
                     <table className={'table table-striped table-hover'}>
                        <thead>
                           <tr>
                              <th>#</th>
                              <th style={{ minWidth: '200px' }}>Name</th>
                              <th style={{ minWidth: '120px' }} />
                              <th style={{ textAlign: 'center' }}>Language</th>
                              <th style={{ textAlign: 'center' }}>Category</th>
                              <th style={{ minWidth: '120px' }} />
                           </tr>
                        </thead>
                        <tbody>
                           {
                              videoList.map((item, index) => (
                                 <tr key={index}>
                                    <th>{index + 1}</th>
                                    <td>{item.videoName}</td>
                                    <td style={{ minWidth: "120px" }}>
                                       <Button type='primary btn-sm form-control' title='Open Link' onClick={() => openInNewTab(item.link)} />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>{item.languageName}</td>
                                    <td style={{ textAlign: 'center' }}><SmallCategory title={item.categoryName} color={item.color} /></td>
                                    <td style={{ minWidth: "120px" }}>
                                       <Button type='primary btn-sm form-control' title='Add Library' onClick={() => addToLibrary(item)} />
                                    </td>
                                 </tr>
                              ))
                           }
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </UserLayout>
      </>
   )
}

export default SearchBookPage
