import { ReactNode } from 'react';
import { MenuDark } from 'components/menu'

interface Props {
   title?: string;
   col?: string;
   off?: string;
   children: ReactNode;
}

const WebLayout = ({ title, col = '12', off = '0', children }: Props) => {

   return (
      <>
         <div className="container-fluid">
            <div className="row animated fadeIn fast custom-p-mobile">
               <MenuDark />
               <br />
               <div className={`col-sm-${col} offset-sm-${off}`}>
                  <h2>{title}</h2>
                  {children}
               </div>
            </div>
         </div>
      </>
   )
}

export default WebLayout
