

const ButtonUnsubscribe = () => {
   return (
      <>
         <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=8AVFDWYHSCMAS" target="blank">
            <img src="https://www.paypalobjects.com/en_US/i/btn/btn_unsubscribe_SM.gif" />
         </a>
      </>
   );
}

export default ButtonUnsubscribe
