import { Dispatch, SetStateAction } from "react";
import { Button as ButtonModal, Modal } from "react-bootstrap";

interface Props {
   showModalWarning: boolean;
   setShowModalWarning: Dispatch<SetStateAction<boolean>>;
   message: string;
}

const Warnings = ({ showModalWarning, setShowModalWarning, message }: Props) => {

   const handleBack = () => setShowModalWarning(false);

   return (
      <>
         <Modal show={showModalWarning} onHide={handleBack}>
            <Modal.Header closeButton>
               <Modal.Title>Warning!</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <div className="alert alert-warning">{message}</div>
            </Modal.Body>

            <Modal.Footer>
               <ButtonModal variant="secondary" onClick={handleBack}>Close</ButtonModal>
            </Modal.Footer>
         </Modal>
      </>
   );
};

export default Warnings;

