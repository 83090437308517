import { useEffect, useState } from "react";
import { Button } from "components/atoms"
import { DataAccountAdmin } from "interfaces";
import AdminLayout from "layout/AdminLayout"
import { AdminAccountServices } from "services";
import { Button as ButtonModal, Modal } from "react-bootstrap";


const AccountsAdminPage = () => {
   const { adminAccount, adminAccountInsert, adminAccountDelete } = AdminAccountServices();
   const [accounts, setAccounts] = useState<DataAccountAdmin[]>([]);
   const [adminKey, setAdminKey] = useState('');
   const [mail, setMail] = useState('');
   const [password, setPassword] = useState('');
   const [showConfirm, setShowConfirm] = useState(false);
   const [reload, setReload] = useState(false);
   const [show, setShow] = useState(false);

   useEffect(() => {
      (async () => {
         await adminAccount().then((res) => {
            setAccounts(res.data);
         })
      })();
   }, [reload])

   const handleClose = () => setShow(false)
   const handleConfirmClose = () => setShowConfirm(false);
   const handleShow = (type: boolean) => {
      if (!type) cancel();
      setShow(true);
   }

   const handleConfirm = (adminKey: string) => {
      setAdminKey(adminKey);
      setShowConfirm(true);
   }

   const cancel = () => {
      setAdminKey("");
      setMail("");
      setPassword("");
   }

   const handleInsert = async () => {
      const data = { mail, password };
      await adminAccountInsert(data).then((res) => {
         if (res.status === "success") {
            setReload(true);
            setShow(false);
            cancel();
         } else {
            //TODO: Mensaje de error
         }
      })
   }

   const handleDelete = async () => {
      await adminAccountDelete(adminKey).then((res) => {
         setReload(true);
         setShowConfirm(false);
      })

   }


   const ModalAccountInsert = (
      <Modal show={show} onHide={handleClose}>
         <Modal.Header>
            <Modal.Title>Register adin</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <div className={'mb-3'}>
                  <label className="form-label">Admin Mail</label>
                  <input type="mail" onChange={({ target }) => setMail(target.value)} value={mail} className="form-control" required />
               </div>
            </div>
            <div className="row">
               <div className={'mb-3'}>
                  <label className="form-label">Admin Password</label>
                  <input type="password" onChange={({ target }) => setPassword(target.value)} value={password} className="form-control" required />
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <Button type={'primary form-control'} title={'Add'} onClick={handleInsert} />
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <ButtonModal variant="secondary" onClick={handleClose}>
               Close
            </ButtonModal>
         </Modal.Footer>
      </Modal>
   )

   const ModalConfirmDelete = (
      <Modal show={showConfirm} onHide={handleConfirmClose}>
         <Modal.Header closeButton>
            <Modal.Title>Confirm Delete Admin</Modal.Title>
         </Modal.Header>

         <Modal.Body>
            <p>This action cannot be reversed!</p>
         </Modal.Body>

         <Modal.Footer>
            <ButtonModal variant="danger" onClick={handleDelete}>Delete</ButtonModal>
            <ButtonModal variant="secondary" onClick={handleConfirmClose}>Close</ButtonModal>
         </Modal.Footer>
      </Modal>
   )

   return (
      <>
         <AdminLayout title="Admin account list" col="6" off="3">
            <div className="col-sm-2 offset-sm-10">
               <Button type={'primary form-control mb-3'} title={'Add'} onClick={() => handleShow(false)} />
            </div>
            <div className="table-responsive table-custom mb-5">
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Mail</th>
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th />
                     </tr>
                  </thead>
                  <tbody>
                     {
                        accounts.map((item, index) => (
                           <tr key={item.adminKey}>
                              <th>{index + 1}</th>
                              <td>{item.mail}</td>
                              <td style={{ textAlign: 'center' }}>{item.statusName}</td>
                              <td><Button type={'danger btn-sm form-control'} title={'Delete'} onClick={() => handleConfirm(item.adminKey)} /></td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
            {ModalAccountInsert}
            {ModalConfirmDelete}
         </AdminLayout>
      </>
   )
}

export default AccountsAdminPage
