import { ChangeEvent, useEffect, useState } from 'react';
import { Button as ButtonModal, Modal } from 'react-bootstrap';
import AdminLayout from "layout/AdminLayout"
import { Bullets, Button, Select } from 'components/atoms';
import { AdminVideoServices, CategoryServices, LanguagesServices } from 'services';
import { Languages, VideoUserAdmin } from 'interfaces';
import { CategoryActiveData } from 'interfaces/category.interface';


const VideoAdminPage = () => {
   const { getActiveCategory } = CategoryServices();
   const { getLanguagesUser } = LanguagesServices();
   const { videoAdmin, videoUserUpdate, videoUserRejected } = AdminVideoServices();
   const [items, setItems] = useState<VideoUserAdmin[]>([]);
   const [pendingItems, setPendingItems] = useState<VideoUserAdmin[]>([]);
   const [processItems, setProcessItems] = useState<VideoUserAdmin[]>([]);
   const [typeValue, setTypeValue] = useState(0);
   const [languages, setLanguages] = useState<Languages[]>([]);
   const [show, setShow] = useState(false);
   const [videoKey, setVideoKey] = useState('');
   const [categoryKey, setCategoryKey] = useState('');
   const [name, setName] = useState('');
   const [link, setLink] = useState('');
   const [categories, setCategories] = useState<CategoryActiveData[]>([]);
   const [languageKey, setLanguageKey] = useState('');

   const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setTypeValue(+e.target.value);
      setItems(+e.target.value === 0 ? pendingItems : processItems);
   };

   useEffect(() => {
      loadItems();
      (async () => {
         await getLanguagesUser().then((res) => {
            setLanguages(res.data);
         });
         await getActiveCategory().then((res) => {
            setCategories(res.data);
         })
      })();
   }, []);

   const loadItems = async () => {
      await videoAdmin().then((res) => {
         setPendingItems(res.data.pending);
         setProcessItems(res.data.process);
         setItems(typeValue === 0 ? res.data.pending : res.data.process);
      });
   };

   const openInNewTab = (videoLink: string) => {
      const newWindow = window.open(
         videoLink,
         '_blank',
         'noopener, noreferrer'
      );
      if (newWindow) newWindow.opener = null;
   };

   const editVideoItem = (item: VideoUserAdmin) => {
      setVideoKey(item.videoKey);
      setCategoryKey(item.categoryKey);
      setName(item.name);
      setLink(item.link);
      setLanguageKey(item.languageKey);
      setShow(true);
   };

   const handleAproved = async () => {
      const data = {
         videoKey,
         categoryKey,
         name,
         link,
         languageKey
      };

      await videoUserUpdate(data).then(() => {
         loadItems();
         setShow(false);
      });
   };

   const handleRejected = async (type: string) => {
      await videoUserRejected(videoKey, type).then(() => {
         loadItems();
         setShow(false);
      });
   };

   const ModalVideoInsert = (
      <Modal show={show} onHide={() => setShow(false)}>
         <Modal.Header>
            <Modal.Title>Manage User Video Register</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <label className="form-label">Category</label>
               <div className={'mb-3'}>
                  <select
                     onChange={({ target }) => setCategoryKey(target.value)}
                     value={categoryKey}
                     className={'form-select'}
                  >
                     {categories.map((item) => (
                        <option key={item.categoryKey} value={item.categoryKey}>
                           {item.name}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className="row">
               <div className={'mb-3'}>
                  <label className="form-label">Video Name</label>
                  <input
                     type="text"
                     onChange={({ target }) => setName(target.value)}
                     value={name}
                     className="form-control"
                     required
                  />
               </div>
            </div>
            <div className="row">
               <div className={'mb-3'}>
                  <label className="form-label">Video Name</label>
                  <select onChange={({ target }) => setLanguageKey(target.value)} value={languageKey} className="form-select" required >
                     <option value="">Select language</option>
                     {languages.map((item, key) => (
                        <option key={key} value={item.languageKey}>
                           {item.name}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className="row">
               <div className="col-sm-4">
                  <Button
                     type={'danger form-control'}
                     title={'Rejected'}
                     onClick={() => handleRejected('rejected')}
                  />
               </div>
               <div className="col-sm-4">
                  <Button
                     type={'warning form-control'}
                     title={'Duplicate'}
                     onClick={() => handleRejected('duplicate')}
                  />
               </div>
               <div className="col-sm-4">
                  <Button
                     type={'primary form-control'}
                     title={'Aproved'}
                     onClick={handleAproved}
                  />
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <ButtonModal variant="secondary" onClick={() => setShow(false)}>
               Close
            </ButtonModal>
         </Modal.Footer>
      </Modal>
   );


   return (
      <>
         <AdminLayout title="Video User Manager" col="10" off="1">
            <div className="col-sm-3">
               <Select
                  label=""
                  name="typeValue"
                  value={typeValue}
                  onChange={onChange}
               >
                  <option value="0">Pending</option>
                  <option value="1">Process</option>
               </Select>
            </div>

            <div className="row">
               <div className="col-12">
                  <div className="table-responsive mb-5">
                     <table className="table table-striped table-hover">
                        <thead>
                           <tr>
                              <th scope="col">#</th>
                              <th scope="col">Language</th>
                              <th scope="col">Category</th>
                              <th scope="col">Name</th>
                              <th scope="col">User</th>
                              <th scope="col">Mail</th>
                              <th />
                              <th />
                              <th />
                           </tr>
                        </thead>
                        <tbody>
                           {items.map((item, index: number) => (
                              <tr key={item.videoKey}>
                                 <td>{index + 1}</td>
                                 <td>{item.languageName}</td>
                                 <td>{item.categoryName}</td>
                                 <td>{item.name}</td>
                                 <td style={{ minWidth: 250 }}>{item.userName}</td>
                                 <td>
                                    <a href={'mailto:' + item.mail}>
                                       {item.mail}
                                    </a>
                                 </td>
                                 <td>{typeValue === 0 && (item.duplicate === "1" && <Bullets type="danger" title="Posibbly duplicate" />)}</td>
                                 <td style={{ minWidth: 100 }}>
                                    <Button
                                       type={'primary btn-sm'}
                                       title={'Open Link'}
                                       onClick={() =>
                                          openInNewTab(item.link)
                                       }
                                    />
                                 </td>
                                 <td>
                                    {typeValue === 0 ? (
                                       <Button
                                          type="success btn-sm form-control"
                                          title="Edit"
                                          onClick={() => editVideoItem(item)}
                                       />
                                    ) : (
                                       <Bullets
                                          type={
                                             item.statusName === 'Approved'
                                                ? 'success'
                                                : (item.statusName === 'Rejected' ? 'danger' : 'warning')
                                          }
                                          title={item.statusName.toUpperCase()}
                                       />
                                    )}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
            {ModalVideoInsert}
         </AdminLayout>
      </>
   )
}

export default VideoAdminPage
