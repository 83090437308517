import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Bullets, ButtonStarOff, SmallCategory } from "components/atoms";
import { CatalogueData } from "interfaces";
import { CatalogueServices } from "services";
import { Alerts } from "components";

interface Props {
   userKey: string;
   reload: boolean;
   setReload: Dispatch<SetStateAction<boolean>>;
}

const ViewLibrary = ({ userKey, reload, setReload }: Props) => {
   const { getCalalogue, updateFavorite } = CatalogueServices();
   const [catalogue, setCatalogue] = useState<CatalogueData[]>([]);
   const [searchTerm, setSearchTerm] = useState('');
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      setLoading(true);
      (async () => {
         await getCalalogue(userKey).then((res) => {
            setCatalogue(res.data);
         }).finally(() => {
            setReload(false);
         }).finally(() => setLoading(false));
      })();
   }, [reload])

   const changeFavorite = async (itemVideoKey: string, itemFavorite: number) => {
      const data = {
         userKey,
         videoKey: itemVideoKey,
         favorite: itemFavorite
      }

      await updateFavorite(data).then((res) => {
         if (res.status === "success") {
            setReload(true);
         } else {
            //TODO
         }
      })
   }

   const openInNewTab = (videoKey: string) => {
      const url = `https://www.progresemos.club/#/open/link/${userKey}/${videoKey}`;
      const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
      if (newWindow) newWindow.opener = null;
   };

   return (
      <div className="row">
         <div className="col-12">
            <input
               type="text"
               className='form-control'
               placeholder='Filter video by name'
               onChange={(e) => setSearchTerm(e.target.value)}
            />
         </div>
         <div className="col-sm-12">
            {loading && <Alerts type="primary" text="One moment please..." />}
         </div>
         <div className="col-sm-12 mt-3">
            <div className="table-responsive mb-5">
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th style={{ minWidth: '200px' }}>Name</th>
                        <th style={{ minWidth: '120px' }} />
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th style={{ textAlign: 'center' }}>Favorite</th>
                        <th style={{ textAlign: 'center' }}>Language</th>
                        <th style={{ textAlign: 'center' }}>Category</th>
                        <th style={{ minWidth: '150px' }}>Date Send</th>
                     </tr>
                  </thead>
                  <tbody>
                     {catalogue
                        .filter((item) => {
                           if (searchTerm === '') {
                              return item;
                           } else if (
                              item.name
                                 .toLocaleLowerCase()
                                 .includes(searchTerm.toLocaleLowerCase())
                           ) {
                              return item;
                           }
                        })
                        .map((item, index) => (
                           <tr key={index}>
                              <th>{index + 1}</th>
                              <td className="max-table-w">{item.name}</td>
                              <td style={{ textAlign: 'center' }}>
                                 <Bullets type="primary pointer" title="Open Link" onClick={() => openInNewTab(item.videoKey)} />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 <Bullets type={item.linkStatus} title={item.linkStatus.toUpperCase()} />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 <ButtonStarOff onClick={() => changeFavorite(item.videoKey, item.favorite)} />
                              </td>
                              <td style={{ textAlign: 'center' }}>
                                 {item.languageName}
                              </td>
                              <td style={{ minWidth: '250px', textAlign: 'center' }} >
                                 <SmallCategory title={item.categoryName} color={item.color} />
                              </td>
                              <td style={{ minWidth: '210px' }}>
                                 {item.dateRegister}
                              </td>
                           </tr>
                        ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
}

export default ViewLibrary
