import api from "./http-commons";
import { LanguagesResult } from "interfaces";

export const LanguagesServices = () => {

   const getLanguagesUser = async () => {
      const res = await api.get('/language/list/user');
      const result: LanguagesResult = res.data;
      return result;
   }

   const getLanguages = async () => {
      const res = await api.get(`/language/list`);
      const items: LanguagesResult = res.data;
      return items;
   };

   return {
      getLanguagesUser,
      getLanguages
   }
};

