import { useEffect, useState } from "react";
import { Button as ButtonModal, Modal } from 'react-bootstrap';
import { DataCategoryWeb } from "interfaces/adminCategory.interface";
import AdminLayout from "layout/AdminLayout"
import { AdminCategoryServices } from "services";
import { Bullets, Button } from "components/atoms";


const CategoryAdminPage = () => {
   const { categoryAdmin, categoryAdminStatus, categoryAdminInsert, categoryAdminUpdate } = AdminCategoryServices();
   const [categories, setCategories] = useState<DataCategoryWeb[]>([]);
   const [searchTerm, setSearchTerm] = useState("")
   const [categoryKey, setCategoryKey] = useState("");
   const [name, setName] = useState("");
   const [description, setDescription] = useState("");
   const [color, setColor] = useState("yellow");
   const [reload, setReload] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [modeInsert, setModeInsert] = useState(true);

   useEffect(() => {
      (async () => {
         await categoryAdmin().then((res) => {
            setCategories(res.data);
         }).finally(() => {
            setReload(false);
            setShowModal(false);
         });
      })();
   }, [reload]);

   const handleShow = (type: boolean) => {
      if (!type) cancel();
      setShowModal(true);
   }

   const handleInsert = async () => {
      const data = { name, description, color };
      await categoryAdminInsert(data).then((res) => {
         if (res.status === "success") {
            setReload(true);
         } else {
            //TODO: Mensaje de error
         }
      })
   }

   const handleChangeStatus = async (item: DataCategoryWeb) => {
      const data = {
         categoryKey: item.categoryKey,
         status: item.status
      }

      await categoryAdminStatus(data).then((res) => {
         if (res.status === 'success') {
            setReload(true);
         } else {
            //TODO: Mensaje de error
         }
      })
   }

   const handleUpdate = async () => {
      const data = { categoryKey, name, description, color };
      await categoryAdminUpdate(data).then((res) => {
         if (res.status === "success") {
            setReload(true);
         } else {
            //TODO: Mensaje de error
         }
      })
   }

   const handleEdit = (item: DataCategoryWeb) => {
      setModeInsert(false);
      setCategoryKey(item.categoryKey);
      setName(item.name);
      setDescription(item.description);
      setColor(item.color);
      handleShow(true);
   }

   const cancel = () => {
      setModeInsert(true);
      setCategoryKey("");
      setName("");
      setDescription("");
      setColor('yellow');
   }

   const ModalCategoryManage = (
      <Modal show={showModal} onHide={() => setShowModal(false)}>
         <Modal.Header>
            <Modal.Title>Regiter of open links</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div className="row">
               <div className={'mb-3'}>
                  <label className="form-label">Category Name</label>
                  <input type="text" onChange={({ target }) => setName(target.value)} value={name} className="form-control" required />
               </div>
            </div>
            <div className="row">
               <div className={'mb-3'}>
                  <label className="form-label">Category Description</label>
                  <textarea onChange={({ target }) => setDescription(target.value)} value={description} className="form-control" rows={3} required />
               </div>
            </div>
            <div className="row">
               <label className="form-label">Category Color</label>
               <div className={'mb-3'}>
                  <select onChange={({ target }) => setColor(target.value)} value={color} className={'form-select'}>
                     <option value="yellow">Yellow</option>
                     <option value="blue">Blue</option>
                     <option value="green">Green</option>
                     <option value="red">Red</option>
                     <option value="purple">Purple</option>
                     <option value="gray">Gray</option>
                     <option value="mint">Mint</option>
                     <option value="aqua">Aqua</option>
                     <option value="lavander">Lavander</option>
                     <option value="pink">Pink</option>
                  </select>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  {
                     modeInsert
                        ? <Button type={'primary form-control'} title={'Add'} onClick={handleInsert} />
                        : <Button type={'success form-control'} title={'Edit'} onClick={handleUpdate} />
                  }
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <ButtonModal variant="secondary" onClick={() => setShowModal(false)}>
               Close
            </ButtonModal>
         </Modal.Footer>
      </Modal>
   );

   return (
      <>
         <AdminLayout title="Category list" col="10" off="1">
            <div className="row">
                <div className="col-sm-10">
                    <input type="text" className='form-control' placeholder='Filter category by name' onChange={e => {setSearchTerm(e.target.value)}}/>
                </div>
                <div className="col-sm-2">
                    <Button type='primary form-control mb-3' title='Add' onClick={() => handleShow(false)}/>
                </div>
            </div>
            <div className="table-responsive table-custom mb-5">
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th style={{ minWidth: '200px' }}>Name</th>
                        <th style={{ textAlign: 'center' }}>Color</th>
                        <th style={{ minWidth: '50px', textAlign: 'center' }}>Pref.</th>
                        <th style={{ minWidth: '300px' }}>Description</th>
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th />
                        <th />
                     </tr>
                  </thead>
                  <tbody>
                     {
                        categories.filter((item) => {
                           if (searchTerm === "") {
                              return item;
                           } else if (item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                              return item;
                           }
                        }).map((item, index) => (
                           <tr key={item.categoryKey}>
                              <th>{index + 1}</th>
                              <td>{item.name}</td>
                              <td style={{ textAlign: 'center' }}>
                                 <Bullets type={item.color} title={item.color} />
                              </td>
                              <td style={{ textAlign: 'center' }}>{item.preferences}</td>
                              <td>{item.description}</td>
                              <td style={{ textAlign: 'center' }}>{item.statusName}</td>
                              <td><Button type={'success btn-sm form-control'} title={'Edit'} onClick={() => handleEdit(item)} /></td>
                              <td><Button type={'warning btn-sm form-control'} title={'Status'} onClick={() => handleChangeStatus(item)} /></td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
            {ModalCategoryManage}
         </AdminLayout>
      </>
   )
}

export default CategoryAdminPage
