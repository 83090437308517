import { WebLayout } from "layout"

const ContactPage = () => {
   return (
      <>
         <WebLayout title="Contact" col="4" off="4">
            <p><strong>Get the information you’re looking for.</strong></p>
            <p>
              Would you like to know what we stand for? <br />
               Email us to{' '}
               <a href="mailto:info@progresemos.club">
                  info@progresemos.club
               </a>
            </p>
            <p>
               Technical support <br />
               Email us to{' '}
               <a href="help@progresemos.club">help@progresemos.club</a>
            </p>
            <p>
               24/7 Support line:  <a href="tel:929-340-6135">929-340-6135</a>
            </p>
         </WebLayout>
      </>
   )
}

export default ContactPage
