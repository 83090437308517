import { MenuDark } from 'components/menu';
import { BookRegister } from 'pages';
import { useEffect, useState } from 'react';


const FreeBookRegisterPage = () => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reload) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 4000);
      return () => {
        setReload(false);
        clearTimeout(timer);
      };
    }
  }, [reload]);

  return (
    <>
      <div className="container-fluid">
        <div className="row animated fadeIn fast">
          <MenuDark />
          <br />
          <div className="col-sm-10 offset-sm-1">
            <h2>Sharing is caring</h2>
            <p>
            Progresemos appreciates your support and willingness to share your favorite audiobooks. If the book you would like to share, doesn’t have an audible version yet, we will reproduce it, simply share the book’s link. All share audiobooks will be validated and approved by our team.
            </p>
            <BookRegister setReload={setReload} />
          </div>
          <div className="col-sm-10 offset-sm-1">
            {loading && (
              <div className="alert alert-primary fadeIn fast mb-3" role="alert">
                Thanks for collaborating with Progresemos Club ...
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeBookRegisterPage
