import "./AlertCategory.scss";

interface Props {
   title: string;
   color: string;
}

const AlertCategory = ({ title, color }: Props) => {

   return (
      <>
         <div className={`card-category-${color} card-button`}>
            <div className="row">
               <div className="col-12">
                  <h5>{title}</h5>
               </div>
            </div>
         </div>
      </>
   );
}

export default AlertCategory
