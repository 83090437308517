import { ReactNode } from "react";
import { MenuAdmin } from "components/menu"

interface Props {
   title?: string;
   col?: string;
   off?: string;
   children: ReactNode;
}

const AdminLayout = ({ title, col = '12', off = '0', children }: Props) => {
   return (
      <>
         <div className="container-fluid">
            <MenuAdmin />
            <div className="row animated fadeIn fast custom-p-mobile">
               <br />
               <div className={`col-sm-${col} offset-sm-${off}`}>
                  <h2>{title}</h2>
                  {children}
               </div>
            </div>
         </div>
      </>
   )
}

export default AdminLayout 
