import { Alerts, DeleteConfirm } from "components";
import Warnings from "components/alerts/Warning";
import { Button } from "components/atoms";
import { DataAdminVideo } from "interfaces/adminVideo.interface";
import { CategoryActiveData } from "interfaces/category.interface";
import { Languages } from "interfaces/languages.intergace";
import AdminLayout from "layout/AdminLayout"
import { useEffect, useState } from "react";
import { Button as ButtonModal, Modal } from "react-bootstrap";
import { AdminVideoServices, CategoryServices, LanguagesServices } from "services";


const VideoUserAdminPage = () => {
   const { getActiveCategory } = CategoryServices();
   const { getLanguages } = LanguagesServices();
   const { adminVideo, adminVideoInsert, adminVideoUpdate, adminVideoStatus, adminVideoDelete } = AdminVideoServices();

   const [video, setVideo] = useState<DataAdminVideo[]>([]);
   const [categories, setCategories] = useState<CategoryActiveData[]>([]);
   const [categoryKey, setCategoryKey] = useState('');
   const [searchTerm, setSearchTerm] = useState('');
   const [videoKey, setVideoKey] = useState('');
   const [message, setMessage] = useState("");
   const [name, setName] = useState('');
   const [link, setLink] = useState('');
   const [languageKey, setLanguageKey] = useState("");
   const [languages, setLanguages] = useState<Languages[]>([]);

   const [showModalWarning, setShowModalWarning] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [loading, setLoading] = useState(true);
   const [reload, setReload] = useState(false);
   const [mode, setMode] = useState(false);
   const [show, setShow] = useState(false);

   useEffect(() => {
      (async () => {
         await getActiveCategory().then((res) => {
            setCategories(res.data);
         })
      })();
   }, [])


   const handleShow = (type: boolean) => {
      if (!type) cancel();
      setShow(true);
   }

   const openInNewTab = (videoLink: string) => {
      const newWindow = window.open(videoLink, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
   }

   const cancel = () => {
      setMode(false);
      setVideoKey('');
      setName('');
      setLink('');
      setCategoryKey('');
      setLanguageKey('');
   }

   useEffect(() => {
      (async () => {
         await adminVideo().then(res => {
            setVideo(res.data);
            setLoading(false);
         }).catch(err => {
            setMessage(err);
            setShowModalWarning(true);
         });
         await getLanguages().then((res) => {
            setLanguages(res.data);
         }).finally(() => {
            setLoading(false);
         })
      })();

      setReload(false);
   }, [reload]);

   async function manageVideo(type: number) {
      setLoading(true);
      if (type === 1) {
         if (categoryKey === '') setCategoryKey(categories[0].categoryKey);

         const data = {
            categoryKey,
            name,
            link,
            languageKey
         };

         await adminVideoInsert(data).then((res) => {
            if (res.status == 'success') {
               setReload(true);
            } else {
               setMessage(res.message);
               setShowModalWarning(true);
            }
         })

      } else {
         const data = { videoKey, categoryKey, name, link, languageKey };

         await adminVideoUpdate(data).then((res) => {
            if (res.status == 'success') {
               setReload(true);
            } else {
               setMessage("An error occurred!");
               setShowModalWarning(true);
            }
         })
      }

      setShow(false);
      cancel();
   }

   const handleInsert = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      manageVideo(1).then();
   }

   const handleEdit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      manageVideo(2).then();
   }

   const edit = (item: DataAdminVideo) => {
      setMode(true);
      setVideoKey(item.videoKey);
      setCategoryKey(item.categoryKey);
      setName(item.videoName);
      setLink(item.link);
      setLanguageKey(item.languageKey);
      handleShow(true);
   }

   const changeStatus = async (item: DataAdminVideo) => {
      setLoading(true);
      const data = {
         videoKey: item.videoKey,
         status: item.status
      }

      await adminVideoStatus(data).then((res) => {
         if (res.status === 'success') {
            setReload(true);
         } else {
            setMessage("An error occurred!");
            setShowModalWarning(true);
         }
      })
   }


   const deleteItemTemp = async (videoKey: string) => {
      setLoading(true);
      await adminVideoDelete(videoKey).then(res => {
         if (res.status === "success") {
            setReload(true);
            setShowModalDelete(false);
         }
      }).catch(err => {
         setLoading(false);
         setMessage(err);
         setShowModalWarning(true);
      });
   }

   const confirmDelete = (item: DataAdminVideo) => {
      setVideoKey(item.videoKey);
      setShowModalDelete(true);
   }


   const ModalVideoInsert = (
      <Modal show={show} onHide={() => setShow(false)}>
         <Modal.Header>
            <Modal.Title>Regiter of open links</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <form onSubmit={mode ? handleEdit : handleInsert}>
               <div className="row">
                  <label className="form-label">Category</label>
                  <div className={'mb-3'}>
                     <select onChange={({ target }) => setCategoryKey(target.value)} value={categoryKey} className={'form-select'} required>
                        <option value="">Select Category</option>
                        {
                           categories.map(item => (
                              <option key={item.categoryKey} value={item.categoryKey}>{item.name}</option>
                           ))
                        }
                     </select>
                  </div>
               </div>
               <div className="row">
                  <div className={'mb-3'}>
                     <label className="form-label">Video Name</label>
                     <input type="text" onChange={({ target }) => setName(target.value)} value={name} className="form-control" required />
                  </div>
               </div>
               <div className="row">
                  <div className={'mb-3'}>
                     <label className="form-label">Video Link</label>
                     <textarea onChange={({ target }) => setLink(target.value)} value={link} className="form-control" rows={3} required />
                  </div>
               </div>
               <div className="row">
                  <div className={'mb-3'}>
                     <label className="form-label">Video Language</label>
                     <select onChange={({ target }) => setLanguageKey(target.value)} value={languageKey} className="form-select" required >
                        <option value="">Select Language</option>
                        {languages.map((item, key) => (
                           <option key={key} value={item.languageKey}>
                              {item.name}
                           </option>
                        ))}
                     </select>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     {
                        mode ? (
                           <Button type={'success form-control'} title={'Edit'} />
                        ) : (
                           <Button type={'primary form-control'} title={'Add'} />
                        )
                     }
                  </div>
               </div>
            </form>
         </Modal.Body>
         <Modal.Footer>
            <ButtonModal variant="secondary" onClick={() => setShow(false)}>
               Close
            </ButtonModal>
         </Modal.Footer>
      </Modal>
   )

   return (
      <>
         <AdminLayout title="Links list" col="10" off="1">
            <div className="row">
               <div className="col-sm-10">
                  <input type="text" className={'form-control'} placeholder={'Filter link by name'} onChange={e => { setSearchTerm(e.target.value) }} />
               </div>
               <div className="col-sm-2">
                  <Button type={'primary form-control mb-3'} title={'Add'} onClick={() => handleShow(false)} />
               </div>
                  <div className="col-sm-12">
                  {
                     loading && <Alerts type="primary" text="Loading..." />
                  }
               </div>
            </div>
            <div className="table-responsive table-custom mb-5">
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th style={{ minWidth: '100px' }}>Language</th>
                        <th style={{ minWidth: '200px' }}>Name</th>
                        <th style={{ minWidth: '200px' }}>Category</th>
                        <th style={{ minWidth: '300px' }}>Link</th>
                        <th style={{ textAlign: 'center' }}>Times</th>
                        <th>Status</th>
                        <th />
                        <th />
                        <th />
                        <th />
                     </tr>
                  </thead>
                  <tbody>
                     {
                        video.filter((item) => {
                           if (searchTerm == "") {
                              return item;
                           } else if (item.videoName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                              return item;
                           }
                        }).map((item, index) => (
                           <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{item.languageName}</td>
                              <td>{item.videoName}</td>
                              <td>{item.categoryName}</td>
                              <td>{item.link}</td>
                              <td style={{ textAlign: 'center' }}>{item.openTimes}</td>
                              <td>{item.statusName}</td>
                              <td style={{ minWidth: "120px" }}><Button type={'primary btn-sm form-control'} title={'Open Link'} onClick={() => openInNewTab(item.link)} /></td>
                              <td><Button type={'success btn-sm form-control'} title={'Edit'} onClick={() => edit(item)} /></td>
                              <td><Button type={'warning btn-sm form-control'} title={'Status'} onClick={() => changeStatus(item)} /></td>
                              <td><Button type={'danger btn-sm form-control'} title={'Delete'} onClick={() => confirmDelete(item)} /></td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
            {ModalVideoInsert}
            <Warnings
               showModalWarning={showModalWarning}
               setShowModalWarning={setShowModalWarning}
               message={message}
            />
            <DeleteConfirm
               showModalDelete={showModalDelete}
               setShowModalDelete={setShowModalDelete}
               message={"Deleted information cannot be recovered."}
               deleteItem={deleteItemTemp}
               dataValue={videoKey}
            />
         </AdminLayout>
      </>
   )
}

export default VideoUserAdminPage
