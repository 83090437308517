import api from "./http-commons";
import { PreferencesResult } from "interfaces";

export const PreferencesServices = () => {

   const userPreferences = async (userKey: string) => {
      const res = await api.get(`/user/preferences/${userKey}`);
      const result: PreferencesResult = res.data;
      return result;
   };

   const insertPreferences = async (array: any) => {
      const res = await api.post('/user/preferences', JSON.stringify(array));
      return res.data;
   };

   const deletePreferences = async (array: any) => {
      const { userKey, categoryKey } = array;
      const res = await api.delete(`/user/preferences/${userKey}/${categoryKey}`);
      return res.data;
   };


   return {
      userPreferences,
      insertPreferences,
      deletePreferences
   }
};


