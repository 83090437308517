import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "context/User/UserContext";
import { Button } from "components/atoms";
import { Role } from "utils/roles";
import { UserContextType } from "context/User/UserTypes";
import { removeLocaleStorage } from "utils/session";
import "./Menu.scss";

const MenuAdminUser = () => {
   const history = useNavigate();
   const { userStatusContext, setUserStatusContext, setUserContext } = useContext(UserContext) as UserContextType;

   useEffect(() => {
      if (userStatusContext.role === Role.Web || userStatusContext.role === Role.User) {
         history("/home");
      }
   }, [])

   const handleLogout = () => {
      const userState = {
         isLogged: false,
         role: Role.Web,
         userKey: "",
         userToken: ""
      }
      const user = {
         userKey: "",
         name: "",
         lastName: "",
         mail: "",
         phone: "",
         languageKey: "",
         userStatus: 0
      }

      setUserStatusContext(userState);
      setUserContext(user);
      removeLocaleStorage('progresemos_status_context');
      removeLocaleStorage('progresemos_user_context');
      history("/home");
   }

   return (
      <>
         <nav className="navbar navbar-expand-lg navbar-custom">
            <div className="container-fluid">
               <Link className="navbar-brand" to="/">
                  <img src={process.env.PUBLIC_URL + `/assets/logo-blue.png`} alt="Progresemos" width={150} />
               </Link>
               <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                  aria-expanded="false" aria-label="Toggle navigation">
                  <img src={process.env.PUBLIC_URL + `/assets/bars-blue.png`} alt="Progresemos" />
               </button>
               <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <div className="me-auto" />
                  <ul className="navbar-nav navbar-dark d-flex">
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/admin/account'>Administrators</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/admin/category'>Categories</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/admin/links'>Links</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/admin/users'>Users</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/admin/download/links'>Download</Link>
                     </li>
                     <li className="nav-item">
                        <Link className='nav-link mt-2' to='/admin/video/manager'>Users Videos</Link>
                     </li>
                     <li className="nav-item">
                        <Button onClick={handleLogout} type={'outline-red'} title={'LogOut'} />
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </>
   );
}

export default MenuAdminUser

