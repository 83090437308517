import api from "./http-commons";
import { LoginAdminResponse, LoginData, RegisterData } from "../interfaces/web.interfaces";

export const LoginAdminServices = () => {

   const signInAdmin = async (array: LoginData) => {
      const res = await api.post('/login/admin', JSON.stringify(array));
      const result:LoginAdminResponse = res.data;
      return result;
   }

   const signUpAdmin = async (array: RegisterData) => {
      const res = await api.post('/create/user', JSON.stringify(array));
      const result: LoginAdminResponse = res.data;
      return result;
   }

   const getUserAdmin = async (userKey: string) => {
      const res = await api.get(`/user/${userKey}`);
      return res.data;
   }

   return {
      signInAdmin,
      signUpAdmin,
      getUserAdmin
   }
};

