import { useEffect, useState } from "react";
import { Button as ButtonModal, Modal } from 'react-bootstrap';
import AdminLayout from "layout/AdminLayout"
import Warnings from "components/alerts/Warning";
import { DataCategory, DataLink, DataUserAdmin } from "interfaces";
import { AdminUsersServices } from "services";
import { Bullets, Button } from "components/atoms";

interface iVideoRegister {
   dataOpenLink: string
}

function UsersAdminPage() {
   const { adminUser, adminUserDetail, adminUserStatus, adminVideoRegister, adminUserMemberStatus } = AdminUsersServices();
   const [linksRegister, setLinksRegister] = useState<iVideoRegister[]>([]);
   const [showModalWarning, setShowModalWarning] = useState(false);
   const [category, setCategory] = useState<DataCategory[]>([]);
   const [users, setUsers] = useState<DataUserAdmin[]>([]);
   const [links, setLinks] = useState<DataLink[]>([]);
   const [searchLink, setSearchLink] = useState('');
   const [searchTerm, setSearchTerm] = useState('');
   const [loading, setLoading] = useState(true);
   const [reload, setReload] = useState(false);
   const [message, setMessage] = useState("");
   const [status, setStatus] = useState("0");
   const [show, setShow] = useState(false);
   const [mode, setMode] = useState(true);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   useEffect(() => {
      (async () => {
         await adminUser(status).then((res) => {
            setUsers(res.data);
            setLoading(false);
         }).finally(() => setReload(false));
      })();
   }, [reload]);

   const handleDetail = async (item: DataUserAdmin) => {
      setMode(false);
      setLoading(true);
      setSearchTerm('');
      await adminUserDetail(item.userKey).then((res) => {
         setCategory(res.data.category);
         setLinks(res.data.links);
      }).finally(() => setLoading(false));
   }

   const handleChangeStatus = async (item: DataUserAdmin) => {
      const data = {
         userKey: item.userKey,
         status: item.status
      }

      await adminUserStatus(data).then((res) => {
         if (res.status === 'success') {
            setReload(true);
         } else {
            setMessage(res.message);
            setShowModalWarning(true);
         }
      })
   }

   const handleChangeMembershipStatus = async (item: DataUserAdmin) => {
      const data = {
         userKey: item.userKey,
         memberStatus: item.memberStatus
      }

      await adminUserMemberStatus(data).then((res) => {
         if (res.status === 'success') {
            setReload(true);
         } else {
            setMessage(res.message);
            setShowModalWarning(true);
         }
      })
   }

   const handleVideoRegister = async (item: DataLink) => {
      setLoading(true);

      await adminVideoRegister(item.userKey, item.videoKey).then((res) => {
         setLinksRegister(res.data);
         setLoading(false);
         handleShow();
      })
   }

   const openInNewTab = (item: DataLink) => {
      const newWindow = window.open(item.link, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
   }

   const options = [
      { value: "0", label: 'All users' },
      { value: "1", label: 'Active users' },
      { value: "2", label: 'Disabled users' },
      { value: "3", label: 'Free users' },
      { value: "4", label: 'Premium users' }
   ];


   const UsersBlock = (
      <div className="row">
         <div className="col-sm-3">
            <select className="form-select mt-1" onChange={e => {
               setStatus(e.target.value);
               setReload(true);
            }} value={status}>
               {
                  options.map((item, index) => (
                     <option key={index} value={item.value}>{item.label}</option>
                  ))
               }
            </select>
         </div>
         <div className="col-sm-9 mt-1">
            <input type="text" className={'form-control'} placeholder={'Filter user by name/lastname'} onChange={e => { setSearchTerm(e.target.value) }} />
         </div>
         <div className="col-12">
            {loading && (
               <div className={`alert alert-primary`} role="alert">Loading...</div>
            )}
         </div>
         <div className="col-12 mt-3">
            <div className="table-responsive table-custom mb-5">
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th style={{ minWidth: '100px' }}>LastName</th>
                        <th>Phone</th>
                        <th>Mail</th>
                        <th style={{ textAlign: 'center' }}>L.Sends</th>
                        <th style={{ textAlign: 'center' }}>L.Opens</th>
                        <th style={{ minWidth: '100px' }}>Register</th>
                        <th style={{ textAlign: 'center' }}>Status</th>
                        <th style={{ textAlign: 'center' }}>Member</th>
                        <th />
                        <th />
                        <th />
                     </tr>
                  </thead>
                  <tbody>
                     {
                        users.filter((item) => {
                           if (searchTerm == "") {
                              return item;
                           } else if (item.fullName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                              return item;
                           }
                        }).map((item, index) => (
                           <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{item.name}</td>
                              <td>{item.lastName}</td>
                              <td>{item.phone}</td>
                              <td><a href={"mailto:" + item.mail}>{item.mail}</a></td>
                              <td style={{ textAlign: 'center' }}>{item.linksSend}</td>
                              <td style={{ textAlign: 'center' }}>{item.linksOpen}</td>
                              <td>{item.register}</td>
                              <td style={{ textAlign: 'center' }}>{item.statusName}</td>
                              <td style={{ textAlign: 'center' }}>{item.statusMemberName}</td>
                              <td><Button type={'primary btn-sm form-control'} title={'Detail'} onClick={() => handleDetail(item)} /></td>
                              <td><Button type={'warning btn-sm form-control'} title={'Status'} onClick={() => handleChangeStatus(item)} /></td>
                              <td><Button type={'success btn-sm form-control'} title={'Member'} onClick={() => handleChangeMembershipStatus(item)} /></td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )

   const LinksBlock = (
      <div className="row">
         <div className="col-sm-10">
            <input type="text" className={'form-control'} placeholder={'Filter link by name'} onChange={e => { setSearchLink(e.target.value) }} />
         </div>
         <div className="col-sm-2">
            <Button type={'danger form-control'} title={'Return'} onClick={() => setMode(true)} />
         </div>
         <div className="col-12">
            {
               category.map((item) => (
                  <Bullets key={item.categoryKey} type={`${item.color} mr-10`} title={item.name} />
               ))
            }
         </div>
         <div className="col-12 mt-3">
            <div className="table-responsive table-custom mb-5">
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th style={{ minWidth: '150px' }}>Name</th>
                        <th>Status</th>
                        <th style={{ textAlign: 'center' }}>Category</th>
                        <th style={{ minWidth: '100px' }} />
                        <th style={{ minWidth: '100px' }} />
                     </tr>
                  </thead>
                  <tbody>
                     {
                        links.filter((item) => {
                           if (searchLink == "") {
                              return item;
                           } else if (item.videoName.toLocaleLowerCase().includes(searchLink.toLocaleLowerCase())) {
                              return item;
                           }
                        }).map((item, index) => (
                           <tr key={index}>
                              <th>{index + 1}</th>
                              <td>{item.videoName}</td>
                              <td><Bullets type={item.linkStatus} title={item.linkStatus.toUpperCase()} /></td>
                              <td style={{ textAlign: 'center' }}>{item.categoryName}</td>
                              <td><Button type={'primary btn-sm form-control'} title={'Detail'} onClick={() => handleVideoRegister(item)} /></td>
                              <td><Button type={'primary btn-sm form-control'} title={'Open Link'} onClick={() => openInNewTab(item)} /></td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   )

   function ModalVideoRegister() {
      return (
         <Modal show={show} onHide={handleClose}>
            <Modal.Header>
               <Modal.Title>Regiter of open links</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <table className={'table table-striped table-hover'}>
                  <thead>
                     <tr>
                        <th>#</th>
                        <th style={{ minWidth: '150px' }}>Register</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        linksRegister.map((item, index) => (
                           <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.dataOpenLink}</td>
                           </tr>
                        ))
                     }
                  </tbody>
               </table>
            </Modal.Body>
            <Modal.Footer>
               <ButtonModal variant="secondary" onClick={handleClose}>
                  Close
               </ButtonModal>
            </Modal.Footer>
         </Modal>
      );
   }



   return (
      <>
         <AdminLayout title="User list" col="10" off="1">
            <div className="row">
               {
                  mode ? UsersBlock : LinksBlock
               }
               <ModalVideoRegister />
            </div>
            <Warnings
               showModalWarning={showModalWarning}
               setShowModalWarning={setShowModalWarning}
               message={message}
            />
         </AdminLayout>
      </>
   )
}

export default UsersAdminPage
