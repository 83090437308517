import "./SmallCategory.scss";

interface Props {
   title: string;
   color: string;
}

const SmallCategory = ({ title, color }: Props) => {
   return (
      <>
         <span className={`span-category-${color} span-button`}>
            {title}
         </span>
      </>
   );
}

export default SmallCategory
