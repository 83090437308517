import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AlertCategory, Alerts } from "components";
import { Bullets, ButtonStar } from "components/atoms";
import { CatalogueFavData } from "interfaces";
import { CatalogueServices } from "services";

interface Props {
   userKey: string;
   reload: boolean;
   setReload: Dispatch<SetStateAction<boolean>>;
}

const ViewFavorites = ({ userKey, reload, setReload }: Props) => {
   const { getCalalogueFavorite, updateFavorite } = CatalogueServices();
   const [catalogueFavorite, setCatalogueFavorite] = useState<CatalogueFavData[]>([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      setLoading(true);
      (async () => {
         await getCalalogueFavorite(userKey).then((res) => {
            setCatalogueFavorite(res.data);
         }).finally(() => {
            setReload(false);
         }).finally(() => setLoading(false));
      })();
   }, [reload])

   const openInNewTab = (videoKey: string) => {
      const url = `https://www.progresemos.club/#/open/link/${userKey}/${videoKey}`;
      const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
      if (newWindow) newWindow.opener = null;
   };

   const changeFavorite = async (itemVideoKey:string, itemFavorite:number) => {
      const data = {
         userKey,
         videoKey: itemVideoKey,
         favorite: itemFavorite
      }

      await updateFavorite(data).then((res) => {
         if(res.status === "success"){
            setReload(true);
         } else {
            //TODO
         }
      })
   }


   return (
      <div className="row">
         <div className="col-sm-12">
            {loading && <Alerts type="primary" text="One moment please..." /> }
         </div>
         <div className="col-sm-12 mt-3">
            {catalogueFavorite.map((item, index) => (
               item.blockVideo.length > 0 &&
               <div key={index}>
                  {item.category.map(
                     (cat, catIndex) =>
                        item.blockVideo.length > 0
                        && <AlertCategory key={catIndex} title={cat.name} color={cat.color} />
                  )}
                  <div key={index} className="table-responsive mb-5">
                     <table className={'table table-striped table-hover'}>
                        {item.blockVideo.length > 0 && (
                           <thead>
                              <tr>
                                 <th>#</th>
                                 <th style={{ minWidth: '200px' }}>Name</th>
                                 <th style={{ minWidth: '120px' }} />
                                 <th style={{ textAlign: 'center' }}>Status</th>
                                 <th style={{ textAlign: 'center' }}>Favorite</th>
                                 <th style={{ textAlign: 'center' }}>Language</th>
                                 <th style={{ minWidth: '150px' }}>Date Send</th>
                              </tr>
                           </thead>
                        )}
                        <tbody>
                           {item.blockVideo.map((item, index) => (
                              <tr key={index}>
                                 <th>{index + 1}</th>
                                 <td style={{ maxWidth: "210px" }}>{item.name}</td>
                                 <td style={{ textAlign: 'center' }}>
                                    <Bullets type="primary pointer" title="Open Link" onClick={() => openInNewTab(item.videoKey)} />
                                 </td>
                                 <td style={{ textAlign: 'center' }}>
                                    <Bullets type={item.linkStatus} title={item.linkStatus.toUpperCase()} />
                                 </td>
                                 <td style={{ textAlign: 'center' }}>
                                    <ButtonStar
                                       onClick={() =>
                                          changeFavorite(
                                             item.videoKey,
                                             +item.favorite
                                          )
                                       }
                                    />
                                 </td>
                                 <td style={{ textAlign: 'center' }}>
                                    {item.languageName}
                                 </td>
                                 <td style={{ minWidth: "210px" }}>{item.dateRegister}</td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
}

// <ButtonStar onClick={() => changeFavorite(item.videoKey, +item.favorite)} />
export default ViewFavorites
