import { Check, Uncheck } from "components/atoms";
import "./CardCategory.scss";

interface Props {
   title: string;
   description: string;
   color: string;
   onClick?: () => void;
   type?: number;
}

const CardCategory = (props: Props) => {
   const { title, description, color, onClick, type } = props;

   return (
      <>
         <div
            onClick={onClick}
            className={`card-category-${color} card-button`}
         >
            <div className="row">
               <div className="col-12">
                  <h5>{title}</h5>
               </div>
               <div className="col-10">
                  <p>{description}</p>
               </div>
               <div className="col-2 check-box">
                  {type === 0 ? <Uncheck /> : <Check />}
               </div>
            </div>
         </div>
      </>
   );
}

export default CardCategory
