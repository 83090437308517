import { Button } from 'components/atoms'
import { AuthLayout } from 'layout'
import { useState } from 'react'
import { LoginServices } from 'services'
import { commonText } from 'utils/commons'

const RecoverPage = () => {
  const { recoverAccount } = LoginServices()

  const [message, setMessage] = useState('On moment please...')
  const [typeAlert, setTypeAlert] = useState('primary')
  const [showButton, setShowButton] = useState(true)
  const [loading, setLoading] = useState(false)
  const [mail, setMail] = useState('')

  const handleRecoverPassword = () => {
    (async () => {
      setLoading(true)
      setShowButton(false)
      setMessage('On moment please...')
      setTypeAlert('primary')

      const array = { email: mail }

      await recoverAccount(array)
        .then((res) => {
          if (res.status === 'success') {
            setMessage(
              'An email has just been sent with the instructions to recover your password. In case of not receiving it, check the spam folder.'
            )
            setTypeAlert('success')
          } else {
            setShowButton(true)
            setMessage(
              'Sorry, the mail could not be sent, check with the system administrator.'
            )
            setTypeAlert('danger')
          }
        })
        .catch((err) => {
          setShowButton(true)
          setMessage(err)
          setTypeAlert('danger')
        })
    })()
  }

  return (
    <>
      <AuthLayout>

            <div className="row animated fadeIn fast custom-p-mobile">
               <div className="col-sm-8 offset-sm-2">

                  <h1 className="h3 mb-3 subheader-text">
                    Forgot your password?
                  </h1>
                  <p>
                    Enter your email below to receive your password
                    reset instruction.
                  </p>


                <div className="form-floating mb-3">
                  <input
                    onChange={({ target }) => setMail(target.value)}
                    value={mail}
                    className="form-control"
                    type="text"
                    required
                  />
                  <label>Email address</label>
                </div>
                  {loading && (
                     <div className={`alert alert-${typeAlert}`} role="alert">
                        {message}
                     </div>
                  )}
                  <Button
                     type='primary form-control btn-lg'
                     title={commonText.signIn}
                     onClick={handleRecoverPassword}
                  />
                  <br />
               </div>
            </div>

      </AuthLayout>
    </>
  )
}

export default RecoverPage
