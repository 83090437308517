import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CatalogueServices } from "services";
import './Redirect.scss';


const RedirectPage = () => {
   const {userKey, videoKey} = useParams();
   const { redirectLink } = CatalogueServices();
   const [message, setMessage] = useState('Redirecting');

   useEffect(() => {
      (async () => {
         const array = {
            userKey,
            videoKey,
         };

         await redirectLink(array).then((res) => {
            console.log(res);
            if (res.status === 'success') {
               window.open(res.data[0].link, '_self');
            } else {
               setMessage('Unauthorized link');
            }
         });
      })();
   }, []);

   return (
      <>
         <div className="container redirect-page">
            <div className="row">
               <div className="col-sm-2 offset-sm-5">
                  <p>{message}</p>
                  <img src={require('./tenor.gif')} alt="Progresemos" />
               </div>
            </div>
         </div>
      </>
   );
}

export default RedirectPage
