import { useEffect, useState } from 'react';
import { Button as ButtonModal, Modal } from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import AdminLayout from "layout/AdminLayout"
import { Button } from 'components/atoms';
import { Alerts } from 'components';
import { DataSendLinkItems } from 'interfaces';
import { CatalogueServices } from 'services';
import DownloadsLinksModal from './DownloadsLinks';

interface iItems {
   catalogueMailKey: string;
   register: string;
}


const DownloadsAdminPage = () => {
   const { mailCatalogueList, mailCatalogueGenerate, mailCatalogueGenerateByVideo, mailCatalogueDownload } = CatalogueServices();
   const [fileDetail, setFileDetail] = useState<DataSendLinkItems[]>([]);
   const [downloads, setDownloads] = useState<iItems[]>([]);

   const [loading, setLoading] = useState(false);
   const [reload, setReload] = useState(false);
   const [show, setShow] = useState(false);
   const [showVideoModal, setShowVideoModal] = useState(false);
   const [errorMessage, setErrorMessage] = useState(false);

   const handleClose = () => setShow(false);

   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
   const fileExtension = '.xlsx';

   useEffect(() => {
      (async () => {
         await mailCatalogueList().then((res) => {
            setDownloads(res.data);
            setLoading(false);
         });
      })();

      setReload(false);
   }, [reload]);

   const handleGenerate = async () => {
      setErrorMessage(false);

      await mailCatalogueGenerate().then((res) => {
         if (res.status === 'success') {
            downloadFile(res.catalogueMailKey, 'download').then();
            setReload(true);
         } else {
            setErrorMessage(true);
         }
      });
   };

   const handleGenerateByVideo = async (videoKey: string) => {
      setErrorMessage(false);

      await mailCatalogueGenerateByVideo(videoKey).then((res) => {
         if (res.status === 'success') {
            downloadFile(res.catalogueMailKey, 'download').then();
            setReload(true);
         } else {
            setErrorMessage(true);
         }
      });
   }

   const downloadFile = async (key: string, type: string) => {
      setLoading(true);
      await mailCatalogueDownload(key).then((res) => {
         if (res.status === 'success') {
            if (type === 'download') {
               const ws = XLSX.utils.json_to_sheet(res.data);
               const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
               const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
               const data = new Blob([excelBuffer], { type: fileType });
               FileSaver.saveAs(data, 'download_file' + fileExtension);
            } else {
               setFileDetail(res.data);
               setShow(true);
            }
         }

         setLoading(false);
      });
   };

   const ModalSendVideoDetail = (
      <Modal show={show} fullscreen={true} onHide={handleClose}>
         <Modal.Header>
            <Modal.Title>Regiter of open links</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <table className={'table table-striped table-hover'}>
               <thead>
                  <tr>
                     <th>#</th>
                     <th style={{ minWidth: '150px' }}>User Code</th>
                     <th style={{ minWidth: '150px' }}>Name</th>
                     <th style={{ minWidth: '150px' }}>LastName</th>
                     <th style={{ minWidth: '150px' }}>Mail</th>
                     <th style={{ minWidth: '150px' }}>Video Name</th>
                     <th style={{ minWidth: '150px' }}>Video Link</th>
                  </tr>
               </thead>
               <tbody>
                  {fileDetail.map((item, index) => (
                     <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.userKey}</td>
                        <td>{item.userName}</td>
                        <td>{item.lastName}</td>
                        <td>{item.mail}</td>
                        <td>{item.videoName}</td>
                        <td>{item.link}</td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </Modal.Body>
         <Modal.Footer>
            <ButtonModal variant="secondary" onClick={handleClose}>
               Close
            </ButtonModal>
         </Modal.Footer>
      </Modal>
   );



   return (
      <>
         <AdminLayout title="Downloads links" col="10" off="1">
            <div className="row">
               <div className="col-sm-2 offset-sm-8">
                  <Button type='success form-control mb-3' title='Select Video' onClick={() => setShowVideoModal(true)} />
               </div>
               <div className="col-sm-2">
                  <Button type='primary form-control mb-3' title='Add' onClick={() => handleGenerate()} />
               </div>
               <div className="col-12 mt-3">
                  {errorMessage && (
                     <Alerts type={'danger'} text={'No records found'} />
                  )}
                  <div className="table-responsive table-custom mb-5">
                     <table className={'table table-striped table-hover'}>
                        <thead>
                           <tr>
                              <th>#</th>
                              <th style={{ minWidth: '200px' }}>Register</th>
                              <th />
                              <th />
                           </tr>
                        </thead>
                        <tbody>
                           {downloads.map((item, index) => (
                              <tr key={item.catalogueMailKey}>
                                 <th>{index + 1}</th>
                                 <td>{item.register}</td>
                                 <td>
                                    <Button
                                       type={'secondary btn-sm form-control'}
                                       title={'Detail'}
                                       onClick={() =>
                                          downloadFile(item.catalogueMailKey, 'detail')
                                       }
                                    />
                                 </td>
                                 <td>
                                    <Button
                                       type={'warning btn-sm form-control'}
                                       title={'Download'}
                                       onClick={() =>
                                          downloadFile(item.catalogueMailKey, 'download')
                                       }
                                    />
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                     </table>
                  </div>
               </div>
               <div className="col-sm-12">
                  {loading && <Alerts type={'primary'} text={'Loading...'} />}
               </div>
            </div>
            {ModalSendVideoDetail}
            <DownloadsLinksModal show={showVideoModal} setShow={setShowVideoModal} setItem={handleGenerateByVideo} />
         </AdminLayout>
      </>
   )
}

export default DownloadsAdminPage
