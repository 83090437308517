import api from "./http-commons";
import { AdminVideoInsert, ManageAdminVideo, UserAdminVideo, VideoAdminStatus, VideoUserRegister, VideoUserUpdate } from "interfaces/adminVideo.interface";
import { CommonResponse } from "interfaces";

export const AdminVideoServices = () => {

   const adminVideo = async () => {
      const res = await api.get('/video');
      const result: UserAdminVideo = res.data;
      return result;
   };

   const adminVideoInsert = async (array: AdminVideoInsert) => {
      const res = await api.post('/video', JSON.stringify(array));
      const result: ManageAdminVideo = res.data;
      return result;
   };

   const adminVideoUpdate = async (array: AdminVideoInsert) => {
      const res = await api.put('/video', JSON.stringify(array));
      const result: ManageAdminVideo = res.data;
      return result;
   };

   const adminVideoStatus = async (array: VideoAdminStatus) => {
      const res = await api.post('/video/change/status', JSON.stringify(array));
      return res.data;
   };

   const adminVideoDelete = async (videoKey: string) => {
      const res = await api.delete(`/video/${videoKey}`);
      return res.data;
   };

   const videoUser = async (userKey: string) => {
      const res = await api.get(`/video/register/${userKey}/user`);
      return res.data;
   };

   const videoAdmin = async () => {
      const res = await api.get('/video/register/admin');
      return res.data;
   };

   const videoUserRegister = async (array: VideoUserRegister) => {
      const res = await api.post('/video/register/user', JSON.stringify(array));
      const result:CommonResponse = res.data;
      return result;
   };

   const videoUserUpdate = async (array: VideoUserUpdate) => {
      const res = await api.put('/video/register/user', JSON.stringify(array));
      const result:CommonResponse = res.data;
      return result;
   };

   const videoUserRejected = async (videoKey: string, type: string) => {
      const array = { videoKey, type };
      const res = await api.put('/video/rejected/user', JSON.stringify(array));
      return res.data;
   };

   const videoUserDelete = async (videoKey: string) => {
      const res = await api.delete(`/video/register/${videoKey}/user`);
      return res.data;
   };


   return {
      adminVideo,
      adminVideoInsert,
      adminVideoUpdate,
      adminVideoStatus,
      adminVideoDelete,
      videoUserRegister,
      videoUser,
      videoAdmin,
      videoUserUpdate,
      videoUserDelete,
      videoUserRejected
   }
};

