import api from "./http-commons";
import { CatalogueAdminResult, CatalogueFavResult, CatalogueResult, CommonResponse, FavParams, MailCatalogueGenerateResult, MailCatalogueResult, MailResponse, NewItemCatalogue, OpenLinkResult, PreferencesResult } from "interfaces";

export const CatalogueServices = () => {

   const getCalalogue = async (userKey: string) => {
      const res = await api.get(`/user/catalogue/${userKey}`);
      const result: CatalogueResult = res.data;
      return result;
   };

   const getCalalogueFavorite = async (userKey: string) => {
      const res = await api.get(`/user/catalogue/favorite/${userKey}`);
      const result: CatalogueFavResult = res.data;
      return result;
   };

   const redirectLink = async (array: any) => {
      const res = await api.post('/open/link', JSON.stringify(array));
      const result: OpenLinkResult = res.data;
      return result;
   };

   interface IAddVideoToLibrary {
      userKey: string;
      videoKey: string;
   }
   const addVideoToLibrary = async (array: IAddVideoToLibrary) => {
      const res = await api.post('/user/add/video', JSON.stringify(array));
      const result: CommonResponse = res.data;
      return result;
   };

   const updateFavorite = async (array: FavParams) => {
      const res = await api.put('/favorite', JSON.stringify(array));
      const result: PreferencesResult = res.data;
      return result;
   };

   const mailCatalogueList = async () => {
      const res = await api.get('/mail/catalogue');
      const result: MailCatalogueResult = res.data;
      return result;
   };

   const mailCatalogueGenerate = async () => {
      const res = await api.get('/mail/catalogue/generate');
      const result: MailCatalogueGenerateResult = res.data;
      return result;
   };

   const mailCatalogueGenerateByVideo = async (videoKey: string) => {
      const res = await api.get(`/mail/catalogue/generate/by/video/${videoKey}`);
      const result: MailCatalogueGenerateResult = res.data;
      return result;
   };

   const mailIndividualCatalogueGenerate = async (userKey: string) => {
      const res = await api.get(`/mail/catalogue/individual/${userKey}`);
      const result: MailResponse = res.data;
      return result;
   };

   const mailCatalogueDownload = async (catalogueMailKey: string) => {
      const res = await api.get(`/mail/catalogue/download/${catalogueMailKey}`);
      const result: CatalogueAdminResult = res.data;
      return result;
   };

   const newItemCatalogue = async (data: NewItemCatalogue) => {
      const res = await api.post(`/user/catalogue`, JSON.stringify(data));
      const result: CommonResponse = res.data;
      return result;
   };


   return {
      getCalalogue,
      getCalalogueFavorite,
      redirectLink,
      updateFavorite,
      mailCatalogueList,
      mailCatalogueGenerate,
      mailIndividualCatalogueGenerate,
      mailCatalogueDownload,
      newItemCatalogue,
      addVideoToLibrary,
      mailCatalogueGenerateByVideo
   }
};



