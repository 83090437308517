import "./Button.scss";

interface Props {
   type: string;
   title: string;
   onClick?: () => void;
}

const Button = ({ type, title, onClick }: Props) => {
   return (
      <>
         <button onClick={onClick} className={`btn btn-${type}`} type="submit">{title}</button>
      </>
   );
}

export default Button
