import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from "../context/User/UserContext";
import { UserContextType } from "../context/User/UserTypes";

export const PrivateNavigation = () => {
   const { userStatusContext } = useContext(UserContext) as UserContextType;
   return userStatusContext.isLogged ? <Outlet /> : <Navigate to="/home" replace />
}

