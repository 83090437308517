import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "context/User/UserContext";
import { UserContextType } from "context/User/UserTypes";
import { AuthLayout } from "layout";
import { Button } from "components/atoms";
import { Role } from "utils/roles";
import { commonText } from "utils/commons";
import { setLocalStorage } from "utils/session";
import { LoginAdminServices } from "services";


const LoginAdminPage = () => {
   const history = useNavigate();
   const { setUserStatusContext, setUserContext } = useContext(UserContext) as UserContextType;
   const { signInAdmin } = LoginAdminServices()

   const [loading, setLoading] = useState(false);
   const [typeAlert, setTypeAlert] = useState('primary');
   const [message, setMessage] = useState('Login...');
   const [mail, setMail] = useState('');
   const [password, setPassword] = useState('');

   const handleLogin = async () => {
      setLoading(true);

      if (mail.length === 0) {
         setMessage(commonText.invalidMail);
         setTypeAlert("danger");
         return;
      }

      if (password.length === 0) {
         setMessage(commonText.insertPassword);
         setTypeAlert("danger");
         return;
      }

      const data = { mail, password };

      await signInAdmin(data).then((res) => {
         if (res.status === "success") {
            const userState = {
               isLogged: true,
               role: res.item.level === "0" ? Role.SuperAdmin : Role.Admin,
               userKey: res.item.userKey,
               userToken: res.token
            }
            const adminContext = {
               userKey: res.item.userKey,
               name: "",
               lastName: "",
               mail: "",
               phone: "",
               languageKey: "",
               userStatus: 1
            }
            setUserStatusContext(userState);
            setUserContext(adminContext);
            setLocalStorage("progresemos_status_context", JSON.stringify(userState));
            setLocalStorage("progresemos_user_context", JSON.stringify(res.item));

            history("/admin/home");
         } else {
            setMessage(res.message);
            setTypeAlert('danger');
         }
      }).catch(function(error) {
         setMessage(error.response.data.message);
         setTypeAlert('danger');
      });
   };

   return (
      <>
         <AuthLayout>
            <div className="row animated fadeIn fast custom-p-mobile">
               <div className="col-sm-8 offset-sm-2">
                  <h1 className="h3 mb-3 subheader-text">Admin Sign In</h1>
                  <div className="form-floating mb-3">
                     <input
                        onChange={({ target }) =>
                           setMail(target.value)
                        }
                        value={mail}
                        className="form-control"
                        type="text"
                        required
                     />
                     <label>{commonText.mail}</label>
                  </div>
                  <div className="form-floating mb-3">
                     <input
                        onChange={({ target }) =>
                           setPassword(target.value)
                        }
                        value={password}
                        className="form-control"
                        type="password"
                        required
                     />
                     <label>{commonText.password}</label>
                  </div>
                  {loading && (
                     <div className={`alert alert-${typeAlert}`} role="alert">
                        {message}
                     </div>
                  )}
                  <Button
                     type='primary form-control btn-lg'
                     title={commonText.signIn}
                     onClick={handleLogin}
                  />
                  <br />
                  <Link to='/recover/account'></Link>
               </div>
            </div>
         </AuthLayout>
      </>
   )
}

export default LoginAdminPage
