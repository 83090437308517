import { CategoryResponse, CategoryWeb, RegisterCategoryAdmin, RegisterCategoryStatus, RegisterCategoryUpdate } from "interfaces/adminCategory.interface";
import api from "./http-commons";

export const AdminCategoryServices = () => {


   const categoryAdmin = async () => {
      const res = await api.get('/admin/category');
      const result: CategoryWeb = res.data;
      return result;
   }

   const categoryAdminInsert = async (array: RegisterCategoryAdmin) => {
      const res = await api.post('/admin/category', JSON.stringify(array));
      const result: CategoryResponse = res.data;
      return result;
   }

   const categoryAdminUpdate = async (array: RegisterCategoryUpdate) => {
      const res = await api.put('/admin/category', JSON.stringify(array));
      const result: CategoryResponse = res.data;
      return result;
   }

   const categoryAdminStatus = async (array: RegisterCategoryStatus) => {
      const res = await api.post('/admin/category/change/status', JSON.stringify(array));
      const result: CategoryResponse = res.data;
      return result;
   }

   return {
      categoryAdmin,
      categoryAdminInsert,
      categoryAdminUpdate,
      categoryAdminStatus
   }
};

