import { createContext, ReactNode, useState } from "react";
import { getLocaleStorage } from "../../utils/session";
import { User, UserStatus } from "./UserTypes";

const UserContext = createContext({});

interface Props {
   children: ReactNode;
}

const userLogout = {
   isLogged: false,
   role: "web",
   userKey: "",
   userToken: ""
}

const statusInitialState = getLocaleStorage("progresemos_status_context") === "" ? userLogout : JSON.parse(getLocaleStorage("progresemos_status_context"));
const userInitialState = getLocaleStorage("progresemos_user_context") === "" ? "" : JSON.parse(getLocaleStorage("progresemos_user_context"));

export function UserProvider({ children }: Props) {
   const [userStatusContext, setUserStatusContext] = useState<UserStatus>(statusInitialState);
   const [userContext, setUserContext] = useState<User>(userInitialState);

   return (
      <UserContext.Provider value={{ userStatusContext, setUserStatusContext, userContext, setUserContext }}>
         {children}
      </UserContext.Provider>
   );
};

export default UserContext;
