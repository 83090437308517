import { Link } from "react-router-dom";
import { Button } from "components/atoms";
import { useLogin } from "hooks";
import { commonText } from "utils/commons";
import { AuthLayout } from "layout";


const LoginPage = () => {
   const { loading, typeAlert, mail, setMail, password, setPassword, message, handleLogin } = useLogin();

   return (
      <>
         <AuthLayout>
            <div className="row animated fadeIn fast custom-p-mobile">
               <div className="col-sm-8 offset-sm-2">
                  <h1 className="h3 mb-3 subheader-text">Sign In</h1>
                  <div className="form-floating mb-3">
                     <input
                        onChange={({ target }) =>
                           setMail(target.value)
                        }
                        value={mail}
                        className="form-control"
                        type="text"
                        required
                     />
                     <label>{commonText.mail}</label>
                  </div>
                  <div className="form-floating mb-3">
                     <input
                        onChange={({ target }) =>
                           setPassword(target.value)
                        }
                        value={password}
                        className="form-control"
                        type="password"
                        required
                     />
                     <label>{commonText.password}</label>
                  </div>
                  {loading && (
                     <div className={`alert alert-${typeAlert}`} role="alert">
                        {message}
                     </div>
                  )}
                  <Button
                     type='primary form-control btn-lg'
                     title={commonText.signIn}
                     onClick={handleLogin}
                  />
                  <br />
                  <Link to='/recover/account'>Forgot password?</Link>
               </div>
            </div>
         </AuthLayout>
      </>
   )
}

export default LoginPage
