import { ReactNode } from 'react';
import { MenuDark } from 'components/menu'

interface Props {
   title?: string;
   children: ReactNode;
}

const AuthLayout = ({ title, children }: Props) => {

   return (
      <>
         <div className="container-fluid">
            <div className="row animated fadeIn fast custom-p-mobile">
               <MenuDark />
               <br />
               <div className="col-sm-6 offset-sm-3">
                  <h2>{title}</h2>
                  {children}
               </div>
            </div>
         </div>
      </>
   )
}

export default AuthLayout 
