import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BoxRrss, Button } from "components/atoms";
import { MenuWhite } from "components/menu";
import "./Home.scss";
import UserContext from "context/User/UserContext";
import { UserContextType } from "context/User/UserTypes";
import { Role } from "utils/roles";


const HomePage = () => {
   const { userStatusContext } = useContext(UserContext) as UserContextType;
   const history = useNavigate();

   useEffect(() => {
      if (userStatusContext.isLogged) {
         if (userStatusContext.role === Role.User) {
            history('/user/preferences');
         } else {
            history('/admin/home');
         }
      }
   }, []);


   return (
      <>
         <div className="container-fluid landingpage background-image">
            <div className="row">
               <MenuWhite />

               <div className="col-sm-10 offset-sm-1 desktop-page">
                  <div className="row">
                     <div className="col-12">
                        <h1>Reinvent Yourself</h1>
                        <h2>Change With Your Environment</h2>
                        <h3>and Achieve Success</h3>
                     </div>
                  </div>

                  <div className="row float-box">
                     <div className="col-sm-3">
                        <Link to={'/register'}>
                           {' '}
                           <Button
                              type={'outline-white form-control'}
                              title={'Register'}
                           />{' '}
                        </Link>
                        <br />
                        <br />
                        <Link to={'/free/book/register'}>
                           {' '}
                           <Button type='outline-white form-control' title='Share an Audiobook' />
                           {' '}
                        </Link>

                        <br />
                        <br />
                        <Link to={'/getpremium'}>
                           {' '}
                           <Button
                              type='white form-control'
                              title='Get Premium'
                           />{' '}
                        </Link>

                        <div className="row">
                           <div className="col-12 link-box">
                              <BoxRrss />
                           </div>
                        </div>
                     </div>
                     <div className="col-sm-5 text-box">
                        <p>
                           We are an elite mindset club, designed to connect entrepreneurs with Multilingual audible databases to support their customized self-education
                        </p>
                     </div>
                  </div>
               </div>

               <div className="col-sm-10 offset-sm-1 mobile-page">
                  <div className="row">
                     <div className="col-12">
                        <h1>Reinvent Yourself</h1>
                        <h2>Change With Your Environment</h2>
                        <h3>and Achieve Success</h3>
                     </div>
                  </div>

                  <div className="row body-mobile">
                     <div className="col-sm-4 mb-5">
                        <p>
                           We are an elite mindset club, designed to connect entrepreneurs with Multilingual audible databases to support their customized self-education
                        </p>
                     </div>
                     <div className="col-sm-2">
                        <Link to={'/register'}>
                           {' '}
                           <Button
                              type={'outline-white form-control'}
                              title={'Register'}
                           />{' '}
                        </Link>
                        <br />
                        <br />
                        <Link to={'/free/book/register'}>
                           {' '}
                           <Button
                              type={'outline-white form-control'}
                              title={'Share an Audiobook'}
                           />{' '}
                        </Link>
                        <br />
                        <br />
                        <Link to={'/getpremium'}>
                           {' '}
                           <Button
                              type='white form-control'
                              title='Get Premium'
                           />{' '}
                        </Link>
                     </div>

                     <div className="col-sm-2 offset-sm-3 link-box">
                        <BoxRrss />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default HomePage
