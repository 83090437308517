

const BoxRrss = () => {
   const openInNewTab = (url: string) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
   }

   return (
      <>
         <ul className={'list-inline'}>
            <li className={'list-inline-item'} onClick={() => openInNewTab('https://www.facebook.com/Progresemos-Club-109779288090668/')}>
               <img src={process.env.PUBLIC_URL + `/assets/facebook.png`} alt="Progresemos" />
            </li>
            <li className={'list-inline-item'} onClick={() => openInNewTab('https://www.instagram.com/progresemos.club/')}>
               <img src={process.env.PUBLIC_URL + `/assets/instagram.png`} alt="Progresemos" />
            </li>
            <li className={'list-inline-item'} onClick={() => openInNewTab('https://youtube.com/channel/UCAWOW-bE2ybNYkAqm6Ru0-A')}>
               <img src={process.env.PUBLIC_URL + `/assets/youtube.png`} alt="Progresemos" />
            </li>
         </ul>
      </>
   );
}

export default BoxRrss
