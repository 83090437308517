import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "context/User/UserContext";
import { LoginServices } from "services";
import { Role } from "utils/roles";
import { UserContextType } from "context/User/UserTypes";
import { setLocalStorage } from "utils/session";
import { commonText } from "utils/commons";


export const useLogin = () => {
   const history = useNavigate();
   const { setUserStatusContext, setUserContext } = useContext(UserContext) as UserContextType;
   const { signIn } = LoginServices()

   const [loading, setLoading] = useState(false);
   const [typeAlert, setTypeAlert] = useState('primary');
   const [message, setMessage] = useState('Login...');
   const [mail, setMail] = useState('');
   const [password, setPassword] = useState('');

   const handleLogin = async () => {
      setLoading(true);

      if (mail.length === 0) {
         setMessage(commonText.invalidMail);
         setTypeAlert("danger");
         return;
      }

      if (password.length === 0) {
         setMessage(commonText.insertPassword);
         setTypeAlert("danger");
         return;
      }

      const data = { mail, password };

      await signIn(data).then((res) => {
         if (res.status === "success") {
            const userState = {
               isLogged: true,
               role: Role.User,
               userKey: res.item.userKey,
               userToken: res.token
            }
            setUserStatusContext(userState);
            setUserContext(res.item);
            setLocalStorage("progresemos_status_context", JSON.stringify(userState));
            setLocalStorage("progresemos_user_context", JSON.stringify(res.item));

            history("/user/preferences");
         } else {
            setMessage(res.message);
            setTypeAlert('danger');
         }
      }).catch(function(error) {
         setMessage(error.response.data.message);
         setTypeAlert('danger');
      });
   };

   return {
      loading,
      typeAlert,
      mail,
      setMail,
      password,
      setPassword,
      message,
      handleLogin
   }
}

