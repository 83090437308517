import { Button } from "components/atoms";
import { VideoUserSearchData } from "interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button as ButtonModal, Modal } from "react-bootstrap";
import { VideoServices } from "services/video.services";


interface Props {
   show: boolean;
   setShow: Dispatch<SetStateAction<boolean>>;
   setItem: (videoKey:string) => void;
}

const DownloadsLinksModal = ({show, setShow, setItem}:Props) => {
   const { videoUserSearch } = VideoServices();
   const [video, setVideo] = useState<VideoUserSearchData[]>([]);
   const [searchTerm, setSearchTerm] = useState("");

   useEffect(() => {
      (async () => {
         await videoUserSearch("all", "ALLALLALL", "all").then((res) => {
            setVideo(res.data);
         });
      })();
   }, []);

   const selectVideo = (item:VideoUserSearchData) => {
      setItem(item.videoKey);
      setSearchTerm("");
      setShow(false);
   }

   const openInNewTab = (videoLink: string) => {
      const newWindow = window.open(videoLink, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
   }

   return (
      <>
         <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
            <Modal.Header>
               <Modal.Title>Select a video to generate download</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <div className="col-sm-12">
                  <input type="text" className="form-control" placeholder='Filter link by name' onChange={e => { setSearchTerm(e.target.value) }} />
               </div>
               <div className="table-responsive table-custom mb-5">
                  <table className={'table table-striped table-hover'}>
                     <thead>
                        <tr>
                           <th>#</th>
                           <th style={{ minWidth: '100px' }}>Language</th>
                           <th style={{ minWidth: '200px' }}>Name</th>
                           <th style={{ minWidth: '200px' }}>Category</th>
                           <th style={{ textAlign: 'center' }}>Times</th>
                           <th />
                           <th />
                        </tr>
                     </thead>
                     <tbody>
                        {
                           video.filter((item) => {
                              if (searchTerm == "") {
                                 return item;
                              } else if (item.videoName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                                 return item;
                              }
                           }).map((item, index) => (
                              <tr key={index}>
                                 <th>{index + 1}</th>
                                 <td>{item.languageName}</td>
                                 <td>{item.videoName}</td>
                                 <td>{item.categoryName}</td>
                                 <td style={{ textAlign: 'center' }}>{item.openTimes}</td>
                                 <td style={{ minWidth: "120px" }}><Button type={'primary btn-sm form-control'} title={'Open Link'} onClick={() => openInNewTab(item.link)} /></td>
                                 <td><Button type='warning btn-sm form-control' title="Select" onClick={() => selectVideo(item)} /></td>
                              </tr>
                           ))
                        }
                     </tbody>
                  </table>
               </div>
            </Modal.Body>
            <Modal.Footer>
               <ButtonModal variant="secondary" onClick={() => setShow(false)}>
                  Close
               </ButtonModal>
            </Modal.Footer>
         </Modal>
      </>
   )
}

export default DownloadsLinksModal
