import api from "./http-commons";
import { AccountAdmin, AccountAdminInsert, AccountAdminStatus, AccountAdminUpdate, CommonResponse } from "interfaces";

export const AdminAccountServices = () => {

   const adminAccount = async () => {
      const res = await api.get(`/admin/account`);
      const result:AccountAdmin = res.data;
      return result;
   };

   const adminAccountInsert = async (array:AccountAdminInsert) => {
      const res = await api.post('/admin/account', JSON.stringify(array));
      const result:AccountAdmin = res.data;
      return result;
   }

   const adminAccountUpdate = async (array:AccountAdminUpdate) => {
      const res = await api.post('/admin/account', JSON.stringify(array));
      const result:AccountAdmin = res.data;
      return result;
   }

   const adminAccountStatus = async (array:AccountAdminStatus) => {
      const res = await api.post('/admin/account/change/status', JSON.stringify(array));
      const result: CommonResponse = res.data;
      return result;
   }

   const adminAccountDelete = async (adminKey: string) => {
      const res = await api.delete(`/admin/account/${adminKey}`);
      const result:CommonResponse = res.data;
      return result;
   };

   return {
      adminAccount,
      adminAccountInsert,
      adminAccountUpdate,
      adminAccountStatus,
      adminAccountDelete
   }
};


