export const commonText = {
   signUp: 'Sign Up',
   signIn: 'Sign In',
   moment: 'One moment please...',
   invalidMail: 'Invalid email',
   insertPassword: 'Insert password',
   mail: 'Email Address',
   password: 'Password',
   update: 'Update',
   fieldRequired: 'All fields are required',
   user : {
      name: 'Firts Name',
      lastName: 'Last Name',
      phone: 'Phone',
      languagePreference: 'Preferred language',
   },
   title: {
      userInfo: 'Info User',
   },
   button: {
      next: 'Next',
   },
};
