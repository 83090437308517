import { Button, ButtonSubscription, ButtonUnsubscribe } from "components/atoms";
import { useProfile } from "hooks";
import UserLayout from "layout/UserLayout"


const ProfilePage = () => {
   const { loading, typeAlert, message, name, setName, lastName, setLastName, phone, setPhone, languageKey, setLanguageKey, languages, handleUpdate, memberType } = useProfile();

   return (
      <>
         <UserLayout title="My Profile" col="6" off="3">
            <p>Do you know that even before you were conceived, you won a race of about 100 million
               people? Science has shown that on average, each time men ejaculate, they release
               nearly 100 million sperm, and it only takes one sperm to fertilize a woman&#39;s egg.
               You were a winner even before you were conceived. Use your imagination, there were
               100 million people trying to take your place, but out of all of them, God chose you.
               You are special and a champion.
            </p>
            <br />
            {
               memberType === "Free"
                  ? <div>
                     <h2>Premium Membership</h2>
                     <p>
                        By becoming a premium member, you have unlimited access to all available audiobooks, add them to your library and enjoy them on your own time instead of waiting for them.
                     </p>
                     <p>
                        For a monthly subscription of $5 dollars, you can enjoy all the premium benefits. Cancel at any time.
                     </p>
                     <ButtonSubscription />
                     <br />
                  </div>
                  : <div>
                     <ButtonUnsubscribe />
                     <p className="mt-2">Or send a email to <a href="mailto:unsubscribe@progresemos.club?subject=Unsubscribe">unsubscribe@progresemos.club</a></p>
                     <br />
                     <br />
                  </div>
            }
            <h2>Info User</h2>
            <div className="row">
               <div className="col-sm-6">
                  <div className={'mb-3 form-floating'}>
                     <select onChange={({ target }) => setLanguageKey(target.value)} value={languageKey} className="form-control" required >
                        <option value="">Select language</option>
                        {languages.map((item, key) => (
                           <option key={key} value={item.languageKey}>
                              {item.name}
                           </option>
                        ))}
                     </select>
                     <label>Preferred language</label>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className={'mb-3 form-floating'}>
                     <input type="text" onChange={({ target }) => setName(target.value)} value={name} className="form-control" required />
                     <label>First Name</label>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className={'mb-3 form-floating'}>
                     <input type="text" onChange={({ target }) => setLastName(target.value)} value={lastName} className="form-control" required />
                     <label>Last Name</label>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className={'mb-3 form-floating'}>
                     <input type="text" onChange={({ target }) => setPhone(target.value)} value={phone} className="form-control" required />
                     <label>Phone</label>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className={'mb-3 form-floating'}>
                     <input type="text" value={memberType} className="form-control" disabled={true} />
                     <label>Account Status</label>
                  </div>
               </div>
               <div className="col-12">
                  {
                     loading &&
                     <div className={`alert alert-${typeAlert}`} role="alert">
                        {message}
                     </div>
                  }
               </div>
               <div className="col-sm-3 offset-sm-9 mt-3">
                  <Button type={'primary form-control'} title={'Update Data'} onClick={handleUpdate} />
               </div>
            </div>
         </UserLayout>
      </>
   )
}

export default ProfilePage
