import { useContext, useState } from "react";
import UserContext from "context/User/UserContext";
import ViewFavorites from "./ViewFavorites";
import ViewLibrary from "./ViewLibrary"
import { UserContextType } from "context/User/UserTypes";
import { UserLayout } from "layout";

const LibraryPage = () => {
   const { userContext } = useContext(UserContext) as UserContextType;
   const [reloadFavorite, setReloadFavorite] = useState(false);
   const [reloadLibrary, setReloadLibrary] = useState(false);
   const [favorite, setFavorite] = useState(false);

   const isFavorite = (item: string) => {
      (item === "favorite") ? setReloadFavorite(true) : setReloadLibrary(true);
      setFavorite(item === "favorite");
   }

   return (
      <>
         <UserLayout title="My library" col="10" off="1">
            <p>
               Hard work always pays off. If you practice everything you
               listen and read, sooner or later your hard work will reward
               you.
            </p>
            <p><strong>All your concerns are important to us.</strong></p>
            <p>Email us to{' '} <a href="mailto:help@progresemos.club">help@progresemos.club</a></p>

            <div className="col-sm-2 mb-2">
               <select
                  onChange={({ target }) => isFavorite(target.value)}
                  className="form-select select-box"
                  required
               >
                  <option value="Library">Library</option>
                  <option value="favorite">Favorite</option>
               </select>
            </div>
            {favorite
               ? <ViewFavorites userKey={userContext.userKey} reload={reloadFavorite} setReload={setReloadFavorite} />
               : <ViewLibrary userKey={userContext.userKey} reload={reloadLibrary} setReload={setReloadLibrary} />
            }
         </UserLayout>
      </>
   )
}

export default LibraryPage
