import { DeleteConfirm } from "components";
import { Bullets, Button } from "components/atoms";
import { VideoUserListData } from "interfaces";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { VideoServices } from "services/video.services";


interface Props {
   userKey: string;
   reload: boolean;
   setReload: Dispatch<SetStateAction<boolean>>;
}

const BookRegisterList = ({ userKey, reload, setReload }: Props) => {
   const { videoUserList, videoUserDelete } = VideoServices();
   const [items, setItems] = useState<VideoUserListData[]>([]);
   const [loading, setLoading] = useState(false);
   const [showModalDelete, setShowModalDelete] = useState(false);
   const [videoDelete, setVideoDelete] = useState('');

   useEffect(() => {
      (async () => {
         await videoUserList(userKey).then((res) => {
            setItems(res.data);
         }).finally(() => setReload(false));
      })();
   }, [reload])

   const openInNewTab = (videoLink: string) => {
      const newWindow = window.open(videoLink, '_blank', 'noopener, noreferrer');
      if (newWindow) newWindow.opener = null;
   };

   const confirmDelete = (videoKey: string) => {
      setVideoDelete(videoKey);
      setShowModalDelete(true);
   };

   const handleDeleteVideo = async () => {
      setLoading(true);
      setShowModalDelete(false);
      await videoUserDelete(videoDelete).then((res: any) => {
         if (res.status) {
            setReload(true);
            setLoading(false);
         } else {
            console.log('ERROR');
         }
      });
   };

   const DuplicateMessage = (
      <>
         <br />
         <small style={{ fontSize: '12px', color: '#236ffd' }}>
            Thank you very much for your contribution, currently our catalog has
            the shared link
         </small>
      </>
   );

   return (
      <>
         <div className="col-12 mt-3">
            <div className="table-responsive">
               <table className="table table-striped table-hover table-custom">
                  <thead>
                     <tr>
                        <th scope="col">#</th>
                        <th scope="col">Status</th>
                        <th scope="col">Language</th>
                        <th scope="col">Category</th>
                        <th scope="col">Name</th>
                        <th />
                        <th />
                     </tr>
                  </thead>
                  <tbody style={{ whiteSpace: 'nowrap' }}>
                     {items.map((item, index: number) => (
                        <tr key={item.videoKey}>
                           <td>{index + 1}</td>
                           <td>
                              <Bullets
                                 type={item.statusName === 'Pending' ? 'warning' : 'primary'}
                                 title={item.statusName.toUpperCase()}
                              />
                           </td>
                           <td>{item.languageName}</td>
                           <td>{item.categoryName}</td>
                           <td>{item.name} {item.statusName === 'Duplicate' && DuplicateMessage} </td>
                           <td>
                              <Button
                                 type={'primary btn-sm'}
                                 title={'Open Link'}
                                 onClick={() => openInNewTab(item.link)}
                              />
                           </td>
                           <td>
                              <Button
                                 type="danger btn-sm form-control"
                                 title="Delete"
                                 onClick={() => confirmDelete(item.videoKey)}
                              />
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
         <div className="col-12">
            {loading && (
               <div className="alert alert-primary" role="alert">
                  One moment please...
               </div>
            )}
         </div>
         <DeleteConfirm
            showModalDelete={showModalDelete}
            setShowModalDelete={setShowModalDelete}
            message={'Deleted information cannot be recovered.'}
            deleteItem={handleDeleteVideo}
            dataValue={videoDelete}
         />
      </>
   );
}

export default BookRegisterList
