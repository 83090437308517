import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'utils/useForm';
import { CategoryServices, LanguagesServices } from 'services';
import { Languages } from 'interfaces';
import { Button, Select, TextInput } from 'components/atoms';
import { CategoryActiveData } from 'interfaces/category.interface';
import { VideoServices } from 'services/video.services';

interface Props {
   userKey?: string;
   setReload: Dispatch<SetStateAction<boolean>>;
}

const BookRegister = ({userKey = "", setReload}:Props) => {
   const INITIAL_STATE = {
      userKey,
      categoryKey: "",
      name: "",
      link: "",
      languageKey: "",
   };

   const {getLanguagesUser} = LanguagesServices();
   const {getActiveCategory} = CategoryServices();
   const {videoUserRegister} = VideoServices();
   const { categoryKey, name, link, languageKey, formData, resetForm, onChange } = useForm(INITIAL_STATE);
   const [languages, setLanguages] = useState<Languages[]>([])
   const [categories, setCategories] = useState<CategoryActiveData[]>([])
   const [loading, setLoading] = useState(false);
   const [alert, setAlert] = useState(false);


   useEffect(() => {
      (async () => {
         await getLanguagesUser().then((res) => {
            setLanguages(res.data);
         })
         await getActiveCategory().then((res) => {
            setCategories(res.data);
         })
      })();
   }, [])

   const handleRegisterBook = async () => {
      if (
         formData.categoryKey.length === 0 ||
         formData.name.length === 0 ||
         formData.link.length === 0 ||
         formData.languageKey.length === 0
      ) {
         setAlert(true);
         return;
      }

      setAlert(false);
      setLoading(true);

      await videoUserRegister(formData).then((res: any) => {
         if (res.status) {
            resetForm();
            setReload(true);
            setLoading(false);
         } else {
            console.log('ERROR');
         }
      });
   };


   return (
      <>
         <div className="row">
            <div className="col-sm-3">
               <div className="mb-3">
                  <Select
                     label="Video Language"
                     name="languageKey"
                     value={languageKey}
                     onChange={onChange}
                  >
                     <option value="">Select language</option>
                     {languages.map((item, key) => (
                        <option key={key} value={item.languageKey}>
                           {item.name}
                        </option>
                     ))}
                  </Select>
               </div>
            </div>
            <div className="col-sm-3">
               <div className="mb-3">
                  <Select
                     label="Category"
                     name="categoryKey"
                     value={categoryKey}
                     onChange={onChange}
                  >
                     <option value="">Select category</option>
                     {categories.map((item) => (
                        <option key={item.categoryKey} value={item.categoryKey}>
                           {item.name}
                        </option>
                     ))}
                  </Select>
               </div>
            </div>
            <div className="col-sm-6">
               <div className="mb-3">
                  <TextInput
                     label="Video Name"
                     name="name"
                     value={name}
                     onChange={onChange}
                  />
               </div>
            </div>
         </div>
         <div className="row mb-3">
            <div className="col-sm-10">
               <div className="mb-3">
                  <TextInput
                     label="Link"
                     name="link"
                     value={link}
                     onChange={onChange}
                  />
               </div>
            </div>
            <div className="col-sm-2">
               <Button
                  type={'primary form-control mt-28'}
                  title={'Register'}
                  onClick={handleRegisterBook}
               />
            </div>
            <div className="col-12">
               {loading && (
                  <div className="alert alert-primary" role="alert">
                     One moment please...
                  </div>
               )}
            </div>
            <div className="col-12">
               {alert && (
                  <div className="alert alert-warning" role="alert">
                     All fields are required
                  </div>
               )}
            </div>
         </div>
      </>
   )
}

export default BookRegister
