import "./Bullets.scss";

interface Props {
   type: string,
   title: string,
   onClick?: () => void
}

const Bullets = ({ type, title, onClick }: Props) => {
   return (
      <>
         <span className={`badge bg-${type}`} onClick={onClick}>{title}</span>
      </>
   );
}

export default Bullets
