import { Button } from 'components/atoms'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginServices } from 'services'
import { useParams } from 'react-router-dom'

const ChangePaswordPage = () => {
  const { token } = useParams()
  const history = useNavigate()
  const { updateCredential } = LoginServices()

  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [message, setMessage] = useState('On moment please...')
  const [typeAlert, setTypeAlert] = useState('primary')

  const changePassword = async () => {
    setLoading(true)
    setTypeAlert('primary')
    setMessage('On moment please...')

    if (password !== rePassword) {
      setTypeAlert('danger')
      setMessage('Passwords do not match')
    } else {
      if (token) {
        const array = { token, newPassword: password }

        await updateCredential(array)
          .then((res) => {
            if (res.status === 'success') {
              history('/login')
            } else {
              setTypeAlert('danger')
              setMessage(res.message)
              setShowButton(true)
            }
          })
          .catch((err) => {
            setTypeAlert('danger')
            setMessage(err)
            setShowButton(true)
          })
      } else {
        // TODO: token invalido
      }
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-10 offset-sm-1">
            <div className="row animated fadeIn fast custom-p-mobile">
              <div className="col-sm-4 offset-sm-4">
                <div
                  style={{ textAlign: 'center', marginTop: '100px' }}
                >
                  <img
                    className={'mb-3'}
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/logo-blue.png`
                    }
                    alt="Progresemos"
                    width={150}
                  />
                  <h1 className="h3 mb-3 subheader-text">
                    Password Reset
                  </h1>
                  <p>
                    Enter your new password for your Progresemos
                    account.
                  </p>
                </div>
                <div className="form-floating mb-3">
                  <input
                    onChange={({ target }) =>
                      setPassword(target.value)
                    }
                    value={password}
                    className="form-control"
                    type="password"
                    required
                  />
                  <label>New Password</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    onChange={({ target }) =>
                      setRePassword(target.value)
                    }
                    value={rePassword}
                    className="form-control"
                    type="password"
                    required
                  />
                  <label>Confirm New Password</label>
                </div>
                {loading && (
                  <div
                    className={`alert alert-${typeAlert}`}
                    role="alert"
                  >
                    {message}
                  </div>
                )}
                {showButton && (
                  <Button
                    type={'primary form-control btn-lg'}
                    title={'Change my password'}
                    onClick={() => changePassword()}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangePaswordPage
