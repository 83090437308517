export const setLocalStorage = (key: string, value: string) => {
   window.localStorage.setItem(key, value);
}

export const removeLocaleStorage = (key: string) => {
   window.localStorage.removeItem(key);
};

export const getLocaleStorage = (key: string) => {
   const persistedData = localStorage.getItem(key);
   return persistedData ? persistedData : "";
};

export const setLogout = () => {
   removeLocaleStorage("doc365_user_profile");
   removeLocaleStorage("doc365_user_key");
   removeLocaleStorage("doc365_token");
   removeLocaleStorage("doc365_user_credential");
}
