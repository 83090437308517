interface Props {
   type: "primary" | "secondary" | "danger" | "warning";
   text: string;
}

const Alerts = ({ type, text }: Props) => {
   return (
      <>
         <div className={`alert alert-${type}`} role="alert">
            {text}
         </div>
      </>
   );
}

export default Alerts
