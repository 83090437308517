import AdminLayout from "layout/AdminLayout"


const HomeAdminPage = () => {
   return (
      <>
         <AdminLayout title="Welcome" col="8" off="2">
            <p>Remember, we are the helping hand of the knowledge-hungry</p>
            <p>Every book, audiobook, video, podcast, website, and scholar research papers <br/> must inspire competitive intellectual growth for our members.</p>
            <br/>
            <h3>Pro Behaviors </h3>
            <p><strong style={{fontSize: "21px"}}>P</strong>-Positive mindset changer</p>
            <p><strong style={{fontSize: "21px"}}>R</strong>-Razor-sharp and Receptive</p>
            <p><strong style={{fontSize: "21px"}}>O</strong>-Optimistic creators of oasis</p>
            <br/>
            <h3><strong>We can’t do this without you</strong></h3>
            <p>New IDEAS are valuable and welcome <br/>email them to us <a href="mailto:ideas@progresemos.club">ideas@progresemos.club</a></p>
         </AdminLayout>
      </>
   )
}

export default HomeAdminPage
